import dayjs from 'dayjs';

export const getFormattedDate = (dateToFormat: string, withYear = false) => {
  const date = new Date(dayjs(dateToFormat).format());

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dayOfWeekNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  const dayOfWeek = dayOfWeekNames[date.getDay()];

  const formattedDate = `${month}. ${day} (${dayOfWeek}) ${withYear ? year : ''}`;

  return formattedDate;
};
