import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { jwtDecode } from 'jwt-decode';
import qs from 'qs';

import { API_URL } from 'src/config';
import { showToastErrorMessage } from 'src/shared/utils';

import { store } from '..';
import { User } from '../slices';

const baseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}/api`,
  credentials: 'include',
  paramsSerializer: (params) => {
    return qs.stringify(params, {
      arrayFormat: 'brackets',
    });
  },
  // | Configure base headers here (Authorization etc).
  prepareHeaders: (headers) => {
    const token = store.getState().auth.user?.accessToken;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWrapper: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // | If the request returns a 401, clear the local storage to logout user.
  // @ts-expect-error redux has incorrect type
  if (result?.error && result?.error?.originalStatus === 401) {
    try {
      const refreshResult = await baseQuery(
        {
          url: '/auth/refresh-token',
          method: 'POST',
        },
        api,
        extraOptions,
      );

      const accessToken = (
        refreshResult as {
          data?: {
            data?: {
              accessToken: string;
            };
          };
        }
      ).data?.data?.accessToken;

      if (!accessToken) {
        return result;
      }

      const userData = jwtDecode<Omit<User, 'accessToken'>>(accessToken);

      if (refreshResult.data) {
        api.dispatch({
          type: 'auth/login',
          payload: {
            ...userData,
            accessToken,
          },
        });

        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch({ type: 'auth/logout' });
      }
    } catch (e) {
      showToastErrorMessage((e as Error).message);
    }
  }

  return result;
};

export { baseQueryWrapper as baseQuery };
