import { Dispatch, FC, FormEvent, SetStateAction, useEffect, useState } from 'react';

import { SurveyCategory, SurveySubcategory } from 'src/shared/types';
import { Button } from 'src/shared/ui/button';
import { Modal } from 'src/shared/ui/modal';
import { Spinner } from 'src/shared/ui/spinner';
import { TextField } from 'src/shared/ui/textField';
import { Typography } from 'src/shared/ui/typography';
import { not, showToastErrorMessage } from 'src/shared/utils';
import {
  useCreateSurveySubcategoryMutation,
  useEditSurveySubcategoryMutation,
} from 'src/store/api/survey-subcategory';

type CreateOrUpdateSurveySubcategoryModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  category: SurveyCategory;
  subcategory?: SurveySubcategory;
};

const CreateOrUpdateSurveySubcategoryModal: FC<CreateOrUpdateSurveySubcategoryModalProps> = ({
  isOpen,
  setIsOpen,
  category,
  subcategory,
}) => {
  const [createSubcategory, { isLoading: isCreating }] = useCreateSurveySubcategoryMutation();
  const [updateSubcategory, { isLoading: isUpdating }] = useEditSurveySubcategoryMutation();

  const [name, setName] = useState('');

  const isUpdateState = !!subcategory;
  const isSubmitting = isCreating || isUpdating;
  const isSubcategoryAlreadyExists =
    !!category.subcategories.find((subcategory) => subcategory.name === name) &&
    name !== subcategory?.name;
  const type = isUpdateState ? 'Update' : 'Create';

  const closeModal = () => {
    setIsOpen(false);
    setName('');
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (isUpdateState && subcategory) {
        await updateSubcategory({
          name,
          categoryId: category.id,
          id: subcategory.id,
        }).unwrap();
      } else {
        await createSubcategory({
          name,
          categoryId: category.id,
        }).unwrap();
      }
    } catch (error) {
      showToastErrorMessage(
        `There was an error trying to ${isUpdateState ? 'update' : 'create'} category`,
      );
    } finally {
      closeModal();
    }
  };

  useEffect(() => {
    setName(subcategory?.name || '');
  }, [subcategory, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={closeModal}
    >
      <form
        className="flex flex-col gap-y-6 w-[550px]"
        onSubmit={handleSubmit}
      >
        <Typography variant="h2">{`${type} Subcategory`}</Typography>

        <div className="h-[100px]">
          <TextField
            label="Subcategory Name"
            placeholder="Enter Subcategory name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={isSubcategoryAlreadyExists ? 'Subcategory already exists' : ''}
            isRequired
          />
        </div>

        <div className="flex w-full justify-end gap-x-2">
          <Button
            variant="outlined"
            onClick={closeModal}
            disabled={isSubmitting}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            disabled={isSubcategoryAlreadyExists || not(name) || isSubmitting}
          >
            {isSubmitting ? <Spinner size="sm" /> : type}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { CreateOrUpdateSurveySubcategoryModal };
