import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { not, showToastErrorMessage } from 'src/shared/utils';
import { Spinner } from 'src/shared/ui/spinner';
import { useGetSurveyQuery } from 'src/store/api/survey';

import { navigationCSS } from '../SurveyForm/utils';
import { SurveyNavigation } from '../SurveyForm/Components/SurveyNavigation';

const SurveyAnswer = () => {
  const { formId, answerId } = useParams();

  const navigate = useNavigate();

  const { data: form, isFetching: isLoadingForm } = useGetSurveyQuery(formId || '', {
    refetchOnMountOrArgChange: true,
  });

  const [page, setPage] = useState(0);
  const [survey, setSurvey] = useState<Model>(new Model({}));

  useEffect(() => {
    if (form) {
      setSurvey(new Model(form.content));
    }
  }, [form]);

  if (isLoadingForm) {
    return (
      <Spinner
        withBackdrop
        fallbackText="Loading form..."
      />
    );
  }

  if (not(form)) {
    navigate('/forms');
    showToastErrorMessage('There was an error trying to load the form');

    return null;
  }

  const answer = form.answers.find((answer) => answer.id === answerId);

  survey.onCurrentPageChanged.add((_, options) => {
    setPage(options.newCurrentPage.visibleIndex);
  });

  survey.css = navigationCSS;
  survey.mode = 'display';
  survey.data = answer ? answer.answer : {};

  return (
    <div className="flex flex-col gap-y-4 mt-4 w-full">
      <SurveyNavigation
        form={form}
        survey={survey}
        page={page}
        backButtonHref={`/forms/${formId}/answers`}
      />

      <Survey
        currentPageNo={page}
        model={survey}
      />
    </div>
  );
};

export { SurveyAnswer };
