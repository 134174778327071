import { Dispatch, FC, FormEvent, SetStateAction } from 'react';

import { SurveySubcategory } from 'src/shared/types';
import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { Button } from 'src/shared/ui/button';
import { not, showToastErrorMessage } from 'src/shared/utils';
import { useDeleteSurveySubcategoryMutation } from 'src/store/api/survey-subcategory';

type DeleteSubcategoryModalProps = {
  subcategory: SurveySubcategory;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const DeleteSubcategoryModal: FC<DeleteSubcategoryModalProps> = ({
  subcategory,
  isOpen,
  setIsOpen,
}) => {
  const [deleteSubcategory] = useDeleteSurveySubcategoryMutation();

  const toggleModal = (isOpen: boolean) => {
    setIsOpen(not(isOpen));
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      toggleModal(isOpen);

      await deleteSubcategory(subcategory.id).unwrap();
    } catch (error) {
      showToastErrorMessage(`There was an error trying to delete ${subcategory.name}`);
    }
  };

  const buttons = (
    <div className="flex justify-end gap-2">
      <Button
        type="button"
        variant="outlined"
        color="basic"
        size="lg"
        onClick={closeModal}
      >
        Cancel
      </Button>

      <Button
        type="submit"
        color="danger"
        size="lg"
        autoFocus
      >
        Delete
      </Button>
    </div>
  );

  const totalFormsAmount = subcategory.surveys.length;

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
    >
      <form
        className="flex flex-col justify-between gap-y-6  min-h-[368x] min-w-[451px] max-w-[451px]"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-6">
          <Typography
            variant="h2"
            fontWeight="bold"
          >
            Delete Category
          </Typography>

          <div className="flex flex-col gap-y-1">
            <Typography
              variant="label"
              fontWeight="bold"
            >
              {`Are you sure you want to delete - ${subcategory.name} ?`}
            </Typography>

            <Typography variant="label">{`It has a total of ${totalFormsAmount} forms`}</Typography>
          </div>

          {buttons}
        </div>
      </form>
    </Modal>
  );
};

export { DeleteSubcategoryModal };
