import { FC } from 'react';

import { SurveyCategory } from 'src/shared/types';
import { Typography } from 'src/shared/ui/typography';
import { not } from 'src/shared/utils';
import { useGetSurveyCategoriesQuery } from 'src/store/api/survey-category';

import { SurveySubcategoryCard } from './Features/SurveySubcategoryCard';

type SurveyCategoryContentProps = {
  expandedCategory: SurveyCategory | null;
};

const SurveyCategoryContent: FC<SurveyCategoryContentProps> = ({ expandedCategory }) => {
  const { data: categories = [] } = useGetSurveyCategoriesQuery('');

  const category = categories.find((category) => category.id === expandedCategory?.id);

  if (not(category)) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-4 h-full w-full py-9 px-6">
      <Typography variant="h2">{category.name}</Typography>

      <div className="overflow-y-scroll">
        {category.subcategories.map((subcategory, i) => (
          <SurveySubcategoryCard
            key={subcategory.id}
            subcategory={subcategory}
            category={category}
            lastCard={i !== category.subcategories.length - 1}
          />
        ))}
      </div>
    </div>
  );
};

export { SurveyCategoryContent };
