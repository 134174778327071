import { FC } from 'react';
import { Model } from 'survey-core';

import { BackButton } from 'src/shared/ui/backButton';
import { Button } from 'src/shared/ui/button';
import { Color as ButtonColor } from 'src/shared/ui/button/button.types';
import { SurveyEntity } from 'src/shared/types';
import { UseSetLocalStorageCallback } from 'src/shared/hooks/useLocalStorage';

import { createSurveyPDFModel } from '../utils';

type SurveyNavigationProps = {
  form: SurveyEntity;
  survey: Model;
  page: number;
  backButtonHref?: string;
  withCompleteButton?: boolean;
  setLocalStorageFormData?: UseSetLocalStorageCallback<string | null>;
};

const SurveyNavigation: FC<SurveyNavigationProps> = ({
  form,
  survey,
  page,
  backButtonHref = '/forms',
  withCompleteButton = false,
  setLocalStorageFormData,
}) => {
  const prevPage = () => {
    survey.prevPage();
  };

  const nextPage = () => {
    survey.nextPage();
  };

  const endSurvey = () => {
    survey.completeLastPage();
  };

  const renderButton = (
    text: string,
    onClick: () => void,
    disabled: boolean,
    color: ButtonColor = 'primary',
  ) => {
    return (
      <Button
        color={color}
        onClick={onClick}
        disabled={disabled}
        size="lg"
      >
        {text}
      </Button>
    );
  };

  const clearForm = () => {
    if (setLocalStorageFormData) {
      setLocalStorageFormData(null);
    }
  };

  return (
    <div className="flex gap-x-2 pl-4">
      <BackButton href={backButtonHref} />

      {renderButton('Previous Page', prevPage, page === 0)}

      {renderButton('Next Page', nextPage, page === survey.visiblePages.length - 1)}

      {withCompleteButton &&
        renderButton('Complete', endSurvey, page !== survey.visiblePages.length - 1, 'success')}

      {renderButton(
        'Save as PDF',
        () => createSurveyPDFModel(form.content, survey).save(form.name),
        false,
        'info',
      )}

      {renderButton('Clear Form', clearForm, false)}
    </div>
  );
};

export { SurveyNavigation };
