import { FC, useRef, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';

import { ReactComponent as EditIcon } from 'src/assets/icons/outlined/edit/edit.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/outlined/edit/close.svg';
import { useOutsideClick } from 'src/shared/hooks/useOutsideClick';
import { Typography } from 'src/shared/ui/typography';
import { IconButton } from 'src/shared/ui/iconButton';
import { useAppDispatch } from 'src/store';
import { selectCurrentUser } from 'src/store/slices';
import { useEscapeClick } from 'src/shared/hooks/useEscapeClick';
import { clsx, not } from 'src/shared/utils';
import { Role } from 'src/shared/types';
import { modalConfigActions } from 'src/store/slices/modalConfig';

type DetailsProps = {
  title: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsEditMenuOpen?: (isOpen: boolean) => void;
  hasOutsideClick?: boolean;
  hasHiddenBackground?: boolean;
  className?: string;
};

export const Details: FC<PropsWithChildren<DetailsProps>> = ({
  isOpen,
  setIsOpen,
  setIsEditMenuOpen,
  hasOutsideClick = false,
  hasHiddenBackground = true,
  title,
  className,
  children,
}) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectCurrentUser);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  const detailsRef = useRef<HTMLDivElement>(null);

  const hideDetails = () => {
    if (hasOutsideClick) setIsOpen(false);
  };

  const openEditMenu = () => {
    if (setIsEditMenuOpen) setIsEditMenuOpen(true);
    hideDetails();
    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));
  };

  useEscapeClick(setIsOpen);

  useOutsideClick(detailsRef, hideDetails, isOpen);

  if (!isOpen) {
    return null;
  }

  const content = (
    <div
      ref={detailsRef}
      className={clsx(
        'absolute z-[100] bg-[#fbfbfb] right-0 top-0 h-full w-[532px] pt-6 px-4 pb-4',
        className,
      )}
    >
      <div className="flex flex-col gap-6 h-full">
        <div className="flex justify-between">
          <Typography
            variant="h2"
            className="w-[350px] break-all"
          >
            {title}
          </Typography>

          <div className="flex justify-between items-center gap-2">
            {not(isReadonly) && setIsEditMenuOpen && (
              <IconButton
                color="secondary"
                size="lg"
                onClick={openEditMenu}
                className="w-[48px] h-[48px]"
              >
                <EditIcon />
              </IconButton>
            )}

            <IconButton
              color="secondary"
              size="lg"
              onClick={() => setIsOpen(false)}
              className="w-[48px] h-[48px]"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <div className="flex flex-col gap-6 h-full overflow-y-auto">{children}</div>
      </div>
    </div>
  );

  return isOpen && hasHiddenBackground
    ? createPortal(
        <div className="absolute top-0 right-0 left-0 bottom-0 cursor-default z-[150]">
          <div className="relative w-full h-[calc(100%_-_72px)] mt-[72px] z-[100]">{content}</div>
        </div>,
        document.body,
      )
    : content;
};
