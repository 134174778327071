import { api } from 'src/store/api/api';
import { SurveyAnswerEntity } from 'src/shared/types';

export type CreateSurveyAnswer = {
  answer: any;
  surveyId: string;
};

const surveyAnswerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSurveyAnswers: builder.query<SurveyAnswerEntity[], ''>({
      query: () => ({
        url: '/survey-answers',
        method: 'GET',
      }),
      providesTags: ['SurveyAnswer'],
      transformResponse: (response: { data: SurveyAnswerEntity[] }) => response.data,
    }),
    createSurveyAnswer: builder.mutation<SurveyAnswerEntity, CreateSurveyAnswer>({
      query: (body) => ({
        url: '/survey-answers',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['SurveyAnswer'],
    }),
    editSurveyAnswer: builder.mutation<
      SurveyAnswerEntity,
      CreateSurveyAnswer & {
        answerId: string;
      }
    >({
      query: ({ answerId, ...body }) => ({
        url: `/survey-answers/${answerId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['Survey', 'SurveyAnswer'],
    }),
    deleteSurveyAnswer: builder.mutation<SurveyAnswerEntity, string>({
      query: (id) => ({
        url: `/survey-answers/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(api.util.invalidateTags(['Survey']));
        } catch {
          dispatch(api.util.invalidateTags(['Survey']));
        }
      },
      invalidatesTags: ['Survey', 'SurveyAnswer'],
    }),
  }),
});

export const {
  useGetSurveyAnswersQuery,
  useCreateSurveyAnswerMutation,
  useEditSurveyAnswerMutation,
  useDeleteSurveyAnswerMutation,
} = surveyAnswerApi;
