import { ReactElement, useState } from 'react';

import { Typography } from 'src/shared/ui/typography';
import { CheckboxItem, Checkboxes } from 'src/shared/ui/checkboxes/checkboxes';
import { useGetJobsQuery, useGetTicketsQuery } from 'src/store/api';

import { JobsTable, TicketsTable } from './ui';

type Entity = 'Jobs' | 'Tickets';

const Reports = () => {
  const [selectedEntity, setSelectedEntity] = useState<Entity>('Jobs');

  const {
    data: jobsData = {
      jobs: [],
    },
  } = useGetJobsQuery({});

  const {
    data: ticketsData = {
      tickets: [],
    },
  } = useGetTicketsQuery({});

  const { jobs } = jobsData;
  const { tickets } = ticketsData;

  const entities: {
    label: Entity;
    amount: number;
  }[] = [
    {
      label: 'Jobs',
      amount: jobs.length,
    },
    {
      label: 'Tickets',
      amount: tickets.length,
    },
  ];

  const table: { [key in Entity]: ReactElement } = {
    Jobs: <JobsTable />,
    Tickets: <TicketsTable />,
  };

  return (
    <div className="w-full flex-col px-6">
      <div className="flex justify-between items-center pt-8 pb-4">
        <Typography variant="h2">Reports</Typography>

        <Checkboxes
          options={entities.reduce<CheckboxItem[]>((acc, option) => {
            return [
              ...acc,
              {
                className: 'w-[151px]',
                label: option.label,
                amount: option.amount,
                onClick: () => setSelectedEntity(option.label),
              },
            ];
          }, [])}
          selectedValue={selectedEntity}
          className="w-fit"
        />
      </div>

      {table[selectedEntity]}
    </div>
  );
};

export { Reports };
