import { PropsWithChildren, forwardRef, HTMLAttributes, ReactNode } from 'react';
import { match } from 'ts-pattern';

import { Accordion, AccordionProps } from 'src/shared/ui/accordion';
import { clsx } from 'src/shared/utils/clsx';

type CardProps = PropsWithChildren &
  Omit<HTMLAttributes<HTMLDivElement>, 'title'> &
  AccordionProps & {
    isAccordion?: boolean;
    preTitle?: ReactNode;
    accordionTitleRowClassname?: string;
  };

const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      title,
      preTitle,
      iconPosition = 'right',
      iconSize,
      accordionTitleRowClassname,
      className,
      isAccordion = false,
      ...props
    },
    ref,
  ) => {
    const content = match(isAccordion)
      .with(true, () => (
        <Accordion
          {...props}
          ref={ref}
          iconPosition={iconPosition}
          iconSize={iconSize}
          className={clsx(
            `flex flex-col p-4 gap-y-4 w-full border-[1px] justify-start bg-bgColor-card border-outlineColor-input-border rounded-2xl `,
            className,
          )}
          preTitle={preTitle}
          title={title}
          titleRowClassname={accordionTitleRowClassname}
        >
          {children}
        </Accordion>
      ))
      .with(false, () => (
        <div
          {...props}
          ref={ref}
          className={clsx(
            `flex flex-col p-4 gap-y-4 w-full border-[1px] justify-start bg-bgColor-card border-outlineColor-input-border rounded-2xl `,
            className,
          )}
        >
          {title}

          {children}
        </div>
      ))
      .otherwise(() => null);

    return content;
  },
);

Card.displayName = 'Card';

export { Card };
export type { CardProps };
