import { useState } from 'react';

import { Button } from 'src/shared/ui/button';
import { Typography } from 'src/shared/ui/typography';
import { useGetSurveyNotificationsQuery } from 'src/store/api/survey-notification';
import { ReactComponent as PlusIcon } from 'src/assets/icons/outlined/edit/plus.svg';

import { CreateOrUpdateSurveyNotificationModal, NotificationCard } from './Features';

const Notifications = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { data: notifications = [] } = useGetSurveyNotificationsQuery('');

  return (
    <div className="flex flex-col gap-y-6 w-[1000px] pt-9">
      <div className="flex justify-between w-full">
        <Typography variant="h1">Notifications</Typography>

        <Button
          color="primary"
          size="lg"
          endIcon={<PlusIcon />}
          className="pr-1"
          onClick={() => setIsCreateModalOpen(true)}
        >
          Create Notification
        </Button>
      </div>

      <div className="flex flex-col gap-y-4">
        {notifications.map((notification) => (
          <NotificationCard
            key={notification.id}
            notification={notification}
          />
        ))}
      </div>

      <CreateOrUpdateSurveyNotificationModal
        isOpen={isCreateModalOpen}
        setIsOpen={setIsCreateModalOpen}
      />
    </div>
  );
};

export { Notifications };
