import { Dispatch, FC, FormEvent, SetStateAction, useEffect, useState } from 'react';

import { SurveyNotification, SurveyNotificationTemplate } from 'src/shared/types';
import { MultiSelectInput } from 'src/shared/ui/MultiSelectInput';
import { Button } from 'src/shared/ui/button';
import { IconButton } from 'src/shared/ui/iconButton';
import { Modal } from 'src/shared/ui/modal';
import { SelectInput, SelectInputItem } from 'src/shared/ui/selectInput';
import { ReactComponent as TrashIcon } from 'src/assets/icons/outlined/edit/trash.svg';
import { Spinner } from 'src/shared/ui/spinner';
import { TextField } from 'src/shared/ui/textField';
import { Typography } from 'src/shared/ui/typography';
import { not, showToastErrorMessage } from 'src/shared/utils';
import { useGetAuthPeopleQuery } from 'src/store/api';
import {
  useCreateSurveyNotificationMutation,
  useGetSurveyNotificationTemplatesQuery,
  useSubscribeUsersForSurveyNotificationMutation,
  useUpdateSurveyNotificationMutation,
} from 'src/store/api/survey-notification';

type CreateOrUpdateSurveyNotificationModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  notification?: SurveyNotification;
};

const CreateOrUpdateSurveyNotificationModal: FC<CreateOrUpdateSurveyNotificationModalProps> = ({
  isOpen,
  setIsOpen,
  notification,
}) => {
  const [createNotification, { isLoading: isCreating }] = useCreateSurveyNotificationMutation();
  const [updateNotification, { isLoading: isUpdating }] = useUpdateSurveyNotificationMutation();
  const [subscribeUsersForNotification, { isLoading: isSubscribing }] =
    useSubscribeUsersForSurveyNotificationMutation();

  const { data: templates = [] } = useGetSurveyNotificationTemplatesQuery('');
  const { data: authPeople = [] } = useGetAuthPeopleQuery('');

  const [name, setName] = useState('');
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<SurveyNotificationTemplate | null>(null);

  const template = templates.find((template) => template.id === notification?.emailTemplateId);
  const isUpdateState = !!notification;
  const isSubmitting = isCreating || isUpdating || isSubscribing;
  const type = isUpdateState ? 'Update' : 'Create';

  const closeModal = () => {
    setIsOpen(false);
    setName('');
    setSelectedTemplate(null);
    setSelectedEmails([]);
  };

  const removeEmail = (email: string) => {
    setSelectedEmails(selectedEmails.filter((selectedEmail) => selectedEmail !== email));
  };

  const toggleSelectedEmail = (email: string) => {
    if (selectedEmails.includes(email)) {
      removeEmail(email);
    } else {
      setSelectedEmails([email, ...selectedEmails]);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (isUpdateState && notification) {
        await updateNotification({
          id: notification.id,
          name,
          templateId: selectedTemplate!.id,
        }).unwrap();

        await subscribeUsersForNotification({
          userIds:
            authPeople?.reduce<string[]>((acc, el) => {
              return selectedEmails.includes(el.email) ? [...acc, el.id] : acc;
            }, []) || [],
          notificationId: notification.id,
        }).unwrap();
      } else {
        await createNotification({
          name,
          templateId: selectedTemplate!.id,
        }).unwrap();
      }
    } catch (error) {
      showToastErrorMessage(
        `There was an error trying to ${isUpdateState ? 'update' : 'create'} notification`,
      );
    } finally {
      closeModal();
    }
  };

  useEffect(() => {
    setName(notification?.name || '');
    setSelectedTemplate(template || null);

    if (notification) {
      setSelectedEmails(
        notification.subscriptions.reduce<string[]>((acc, el) => {
          const person = authPeople?.find((person) => person.id === el.userId);

          if (not(person)) {
            return acc;
          }

          return [person.email, ...acc];
        }, []),
      );
    }
  }, [notification, template, isOpen, authPeople]);

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={closeModal}
    >
      <form
        className="flex flex-col gap-y-6 w-[550px]"
        onSubmit={handleSubmit}
      >
        <Typography variant="h2">{`${type} Notification`}</Typography>

        <TextField
          label="Notification Name"
          placeholder="Enter Notification name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          isRequired
        />

        <SelectInput
          isRequired
          name="template"
          value={selectedTemplate?.name}
          label="Template"
          placeholder="Select template"
          onClear={() => setSelectedTemplate(null)}
          items={
            templates.map((template) => ({
              label: (
                <SelectInputItem selected={selectedTemplate?.name === template.name}>
                  {template.name}
                </SelectInputItem>
              ),
              value: template.name,
              onClick: () => setSelectedTemplate(template),
            })) ?? []
          }
        />

        {isUpdateState && (
          <div className="flex flex-col gap-y-3">
            <Typography variant="h3">Set Emails</Typography>

            <MultiSelectInput
              placeholder="Select emails"
              label="Emails"
              items={
                authPeople?.map((person) => ({
                  label: (
                    <SelectInputItem selected={selectedEmails.includes(person.email)}>
                      {person.email}
                    </SelectInputItem>
                  ),
                  value: person.email,
                  onClick: () => toggleSelectedEmail(person.email),
                })) ?? []
              }
            />

            <div className="flex flex-col gap-y-3 h-[198px] overflow-y-scroll">
              {selectedEmails.map((email) => (
                <div
                  key={email}
                  className="flex items-center justify-between border border-[#E4E9F2]  py-2 px-3 rounded-lg"
                >
                  {email}

                  <IconButton
                    color="basic"
                    size="md"
                    iconSize="md"
                    iconClassName="fill-[#231F20]"
                    onClick={() => removeEmail(email)}
                  >
                    <TrashIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="flex w-full justify-end gap-x-2">
          <Button
            variant="outlined"
            onClick={closeModal}
            disabled={isSubmitting}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            disabled={not(name) || not(selectedTemplate) || isSubmitting}
          >
            {isSubmitting ? <Spinner size="sm" /> : type}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { CreateOrUpdateSurveyNotificationModal };
