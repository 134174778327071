import {
  PropsWithChildren,
  ReactElement,
  forwardRef,
  ComponentPropsWithRef,
  memo,
  DOMAttributes,
} from 'react';
import { match } from 'ts-pattern';

import { clsx } from 'src/shared/utils/clsx';

import { Typography } from '../typography';

type TextFieldProps = ComponentPropsWithRef<'input'> &
  PropsWithChildren<{
    label?: string;
    inputClassName?: string;
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    error?: string;
    isRequired?: boolean;
    customIndicator?: ReactElement;
  }>;

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      className,
      inputClassName,
      placeholder,
      startIcon,
      endIcon,
      error,
      customIndicator,
      isRequired = false,
      ...props
    },
    ref,
  ) => {
    const textFieldWithIconClasses = match({
      startIcon,
      endIcon,
    })
      .when(
        ({ startIcon, endIcon }) => startIcon && endIcon,
        () => 'pr-[44px] pl-[48px]',
      )
      .when(
        ({ startIcon }) => startIcon,
        () => 'pl-[48px]',
      )
      .when(
        ({ endIcon }) => endIcon,
        () => 'pr-[44px]',
      )
      .otherwise(() => '');

    const handleKeyDown: DOMAttributes<HTMLDivElement>['onKeyDown'] = (e) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        const activePopper = document.querySelector('[data-id="isActivePopper"]');

        if (activePopper) {
          (activePopper as HTMLElement).focus();
        }
      }
    };

    return (
      <div className={clsx('flex flex-col gap-y-2 w-full', className)}>
        {label && (
          <Typography variant="label">
            {label}

            {isRequired && <span className="text-textColor-danger">*</span>}
          </Typography>
        )}

        <div className={clsx('flex relative h-[48px]', className)}>
          {startIcon}

          <input
            ref={ref}
            onKeyDown={handleKeyDown}
            className={clsx(
              `bg-bgColor-input border border-outlineColor-input-border rounded-xl 
               pl-[16px] pr-[16px] pt-[12px] pb-[12px] w-full`,
              'focus:border-brandingColor-primary',
              `placeholder:text-[16px] placeholder:text-textColor-tertiary placeholder:heading-[24px] placeholder:font-width-400`,
              'disabled:placeholder:text-textColor-disabled',
              error && 'border-semanticColor-danger',
              textFieldWithIconClasses,
              inputClassName,
            )}
            placeholder={placeholder}
            {...props}
          />

          {endIcon}
        </div>

        {error && <p className="text-semanticColor-danger text-[14px] leading-[150%]">{error}</p>}

        {!!customIndicator && customIndicator}
      </div>
    );
  },
);

TextField.displayName = 'TextField';

const memoizedTextField = memo(TextField);

export { memoizedTextField as TextField };
export type { TextFieldProps };
