import { FC } from 'react';
import { useDrop } from 'react-dnd';

import { DropField } from 'src/shared/ui/dropField';
import { ReactComponent as CarIcon } from 'src/assets/icons/outlined/shop/car.svg';
import { clsx, not } from 'src/shared/utils';
import { EquipmentEntity } from 'src/shared/types';

type EquipmentDropfieldProps = {
  title: string;
  sourceEntityId?: string;
  isDropDisabled?: boolean;
  onEquipmentDrop?: (item: EquipmentEntity, key: string) => void;
};

const EquipmentDropfield: FC<EquipmentDropfieldProps> = ({
  title,
  sourceEntityId = '',
  isDropDisabled = false,
  onEquipmentDrop,
}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'equipment',
    drop: (item) => {
      if (onEquipmentDrop) {
        onEquipmentDrop(item as EquipmentEntity, sourceEntityId);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <div ref={isDropDisabled ? undefined : drop}>
      <DropField
        icon={<CarIcon />}
        title={title}
        className={clsx(
          isOver &&
            `rounded-lg bg-bgColor-dropZoneHovered border-brandingColor-primary-gradient transition-[opacity] duration-300 ease-in-out`,
        )}
        isDropDisabled={isDropDisabled}
      />
    </div>
  );
};

export { EquipmentDropfield };
