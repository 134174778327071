import { Dispatch, FC, FormEvent, SetStateAction } from 'react';

import { SurveyCategory } from 'src/shared/types';
import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { Button } from 'src/shared/ui/button';
import { not, showToastErrorMessage } from 'src/shared/utils';
import { useDeleteSurveyCategoryMutation } from 'src/store/api/survey-category';

type DeleteCategoryModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  category: SurveyCategory;
};

const DeleteCategoryModal: FC<DeleteCategoryModalProps> = ({ category, isOpen, setIsOpen }) => {
  const [deleteCategory] = useDeleteSurveyCategoryMutation();

  const toggleModal = (isOpen: boolean) => {
    setIsOpen(not(isOpen));
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      toggleModal(isOpen);

      await deleteCategory(category.id).unwrap();
    } catch (error) {
      showToastErrorMessage(`There was an error trying to delete ${category.name}`);
    }
  };

  const buttons = (
    <div className="flex justify-end gap-2">
      <Button
        type="button"
        variant="outlined"
        color="basic"
        size="lg"
        onClick={closeModal}
      >
        Cancel
      </Button>

      <Button
        type="submit"
        color="danger"
        size="lg"
        autoFocus
      >
        Delete
      </Button>
    </div>
  );

  const totalSubcategoriesAmount = category.subcategories.length;
  const totalFormsAmount = category.subcategories.reduce((acc, el) => acc + el.surveys.length, 0);

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
    >
      <form
        className="flex flex-col justify-between gap-y-6  min-h-[368x] min-w-[451px] max-w-[451px]"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-6">
          <Typography
            variant="h2"
            fontWeight="bold"
          >
            Delete Category
          </Typography>

          <div className="flex flex-col gap-y-1">
            <Typography
              variant="label"
              fontWeight="bold"
            >
              {`Are you sure you want to delete - ${category.name} ?`}
            </Typography>

            <Typography variant="label">
              {`It has a total of ${totalSubcategoriesAmount} subcategories and ${totalFormsAmount} forms`}
            </Typography>
          </div>

          {buttons}
        </div>
      </form>
    </Modal>
  );
};

export { DeleteCategoryModal };
