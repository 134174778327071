import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { Role, SurveyNotification } from 'src/shared/types';
import { Typography } from 'src/shared/ui/typography';
import { ReactComponent as MoreVerticalIcon } from 'src/assets/icons/filled/menus/more-vertical.svg';
import { IconButton } from 'src/shared/ui/iconButton';
import { DropDown, DropDownItem } from 'src/shared/ui/dropDown';
import { clsx } from 'src/shared/utils';
import { CardMenuItem } from 'src/shared/ui/schedulerBoard/ui/cardMenuItem';
import { Icon } from 'src/shared/ui/icon';
import { ReactComponent as TrashIcon } from 'src/assets/icons/outlined/edit/trash.svg';
import { selectCurrentUser } from 'src/store/slices';
import { ADMIN_ROLES } from 'src/shared/constants';
import { ReactComponent as EditIcon } from 'src/assets/icons/outlined/edit/edit.svg';
import { useGetSurveyNotificationTemplatesQuery } from 'src/store/api/survey-notification';

import { CreateOrUpdateSurveyNotificationModal } from './CreateOrUpdateSurveyNotificationModal';
import { DeleteSurveyNotificationModal } from './DeleteSurveyNotificationModal';

type NotificationCardProps = {
  notification: SurveyNotification;
};

const NotificationCard: FC<NotificationCardProps> = ({ notification }) => {
  const user = useSelector(selectCurrentUser);

  const { data: templates = [] } = useGetSurveyNotificationTemplatesQuery('');

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const template = templates.find((template) => template.id === notification.emailTemplateId);
  const isAdmin = ADMIN_ROLES.includes(user?.ProviderRoleMatrix?.userRole as Role);

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  return (
    <div className="flex flex-col gap-y-4 p-4 rounded-lg bg-white">
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-y-1">
          <Typography
            variant="p1"
            fontWeight="bold"
          >
            Name
          </Typography>

          <Typography variant="p1">{notification.name}</Typography>
        </div>

        {isAdmin && (
          <DropDown
            options={{ placement: 'bottom-start' }}
            config={{
              itemsElementClassName: clsx(
                'shadow-[0px_2px_66px_-10px_#0000000F]',
                'rounded-lg',
                'bg-bgColor-card',
              ),
            }}
            renderElement={() => (
              <IconButton
                color="basic"
                size="md"
                iconSize="md"
                iconClassName="fill-textColor-tertiary"
                className="bg-bgColor-ticketCard"
              >
                <MoreVerticalIcon />
              </IconButton>
            )}
            items={
              [
                {
                  value: 'edit',
                  label: (
                    <CardMenuItem
                      onClick={openEditModal}
                      startIcon={
                        <Icon
                          className="fill-textColor-tertiary"
                          icon={<EditIcon />}
                        />
                      }
                      titleClassName="text-textColor-tertiary"
                    >
                      Edit
                    </CardMenuItem>
                  ),
                },
                {
                  value: 'delete',
                  label: (
                    <CardMenuItem
                      onClick={openDeleteModal}
                      startIcon={
                        <Icon
                          className="fill-semanticColor-danger"
                          icon={<TrashIcon />}
                        />
                      }
                      titleClassName="text-semanticColor-danger"
                    >
                      Delete
                    </CardMenuItem>
                  ),
                },
              ].filter(Boolean) as DropDownItem[]
            }
          />
        )}
      </div>

      <div className="flex flex-col gap-y-1">
        <Typography
          variant="p1"
          fontWeight="bold"
        >
          Template
        </Typography>

        <Typography variant="p1">{template?.name}</Typography>
      </div>

      <CreateOrUpdateSurveyNotificationModal
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        notification={notification}
      />

      <DeleteSurveyNotificationModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        notification={notification}
      />
    </div>
  );
};

export { NotificationCard };
