import { forwardRef, useState } from 'react';
import clsx from 'clsx';

import { ReactComponent as ChevronUpIcon } from 'src/assets/icons/outlined/chevrons/chevron-up.svg';
import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/outlined/edit/close-circle.svg';
import { TextField, type TextFieldProps } from 'src/shared/ui/textField';
import { DropDown, DropDownProps } from 'src/shared/ui/dropDown';

import { IconButton } from '../iconButton';

type MultiSelectInputProps = TextFieldProps & {
  items: DropDownProps['items'];
};

const MultiSelectInput = forwardRef<HTMLInputElement, MultiSelectInputProps>(
  ({ items, isRequired = false, ...props }, ref) => {
    const [search, setSearch] = useState('');

    const visibleItems = items.filter((item) => {
      if (typeof item.value !== 'string') {
        return false;
      }

      const formattedSearch = search.toLowerCase();

      return item.value.toLowerCase().includes(formattedSearch);
    });

    const clearSearch = () => {
      setSearch('');
    };

    return (
      <DropDown
        config={{
          sameWidth: true,
          focusLastElement: false,
          setSearchOnSelect: false,
          closeOnSelect: false,
          withBackdrop: false,
          itemsElementClassName: (isOpen) =>
            clsx(
              'rounded-3xl w-full bg-[#F3F6FA] border border-outlineColor-input-border py-[24px]',
              'my-[4px]',
              !isOpen && 'p-0 border-0',
            ),
          setSearch,
        }}
        options={{ placement: 'bottom-start' }}
        items={visibleItems}
        renderElement={(isOpen) => (
          <TextField
            autoComplete="off"
            endIcon={
              <>
                {search && !props.disabled ? (
                  <IconButton
                    size="md"
                    className="absolute right-[40px] top-[4px]"
                    onClick={clearSearch}
                  >
                    <CloseCircleIcon className="fill-[#2E3A59] cursor-pointer" />
                  </IconButton>
                ) : undefined}

                {!props.disabled ? (
                  <ChevronUpIcon
                    className={`fill-[#2E3A59] absolute right-[12px] top-[12px] ${
                      isOpen ? 'rotate-0' : 'rotate-180'
                    }`}
                  />
                ) : undefined}
              </>
            }
            ref={ref}
            {...props}
            isRequired={isRequired}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
      />
    );
  },
);

MultiSelectInput.displayName = 'MultiSelectInput';

export { MultiSelectInput };
export type { MultiSelectInputProps };
