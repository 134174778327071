import { FC } from 'react';

import { ReactComponent as ChevronLeftIcon } from 'src/assets/icons/filled/chevrons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'src/assets/icons/filled/chevrons/chevron-right.svg';
import { PaginationType } from 'src/shared/types';
import { usePagination } from 'src/shared/hooks/usePagination';
import { clsx } from 'src/shared/utils';

import { Button } from '../button';
import { IconButton } from '../iconButton';

import { PageDropDown } from './ui/pageDropDown';
import { PageSizeDropDown } from './ui/pageSizeDropDown';

const ELLIPSIS = '...';

type PaginationProps = {
  pagination: PaginationType;
};

const Pagination: FC<PaginationProps> = ({ pagination }) => {
  const { total, currentPage, onPageChange, pageSize, onPageSizeChange } = pagination;

  const { totalPageCount, paginationRange } = usePagination({
    currentPage,
    pageSize,
    totalCount: total,
  });

  const lastPage = paginationRange[paginationRange.length - 1];

  const handleNext = () => {
    onPageChange(currentPage + 1);
  };

  const handlePrevious = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <div className="flex justify-between w-full bg-white px-4 py-[7px] border-[1px]">
      <div className="flex gap-x-2 items-center">
        <IconButton
          disabled={currentPage === 1}
          onClick={handlePrevious}
          className="w-8 h-8"
        >
          <ChevronLeftIcon />
        </IconButton>

        <ul className="flex items-center gap-x-1 w-[248px]">
          {paginationRange?.map((pageNumber) => {
            if (pageNumber === ELLIPSIS) {
              return (
                <li key={pageNumber}>
                  <PageDropDown
                    currentPage={currentPage}
                    onSelect={onPageChange}
                    totalPages={totalPageCount}
                  />
                </li>
              );
            }

            const isCurrentPage = pageNumber === currentPage;

            return (
              <li key={pageNumber}>
                <Button
                  size="sm"
                  onClick={() => onPageChange(pageNumber)}
                  className={clsx(
                    'min-w-8 w-8 max-h-8 text-textColor-secondary hover:bg-[rgba(18,60,142,0.05)] hover:text-textColor-primary duration-200 transition-all active:text-brandingColor-primary-gradient active:bg-[rgba(18,60,142,0.1)]',
                    isCurrentPage && 'text-brandingColor-primary-gradient bg-[rgba(18,60,142,0.1)]',
                  )}
                >
                  {pageNumber}
                </Button>
              </li>
            );
          })}
        </ul>

        <IconButton
          disabled={currentPage === lastPage}
          onClick={handleNext}
          className="w-8 h-8"
        >
          <ChevronRightIcon />
        </IconButton>
      </div>

      <PageSizeDropDown
        currentSize={pageSize}
        onSelect={onPageSizeChange}
        sizes={[15, 30, 50]}
      />
    </div>
  );
};

export { Pagination };
