import { Model, settings } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useGetSurveyQuery } from 'src/store/api/survey';
import './style.css';
import { not, showToastErrorMessage } from 'src/shared/utils';
import { useCreateSurveyAnswerMutation } from 'src/store/api/surveyAnswer';
import { Spinner } from 'src/shared/ui/spinner';
import { SURVEYS_DATA_API_KEY } from 'src/config';
import { useLocalStorage } from 'src/shared/hooks/useLocalStorage';

import { navigationCSS } from './utils';
import { SurveyNavigation } from './Components/SurveyNavigation';

settings.web.onBeforeRequestChoices = (_sender, options) => {
  options.request.setRequestHeader('x-api-key', SURVEYS_DATA_API_KEY);
};

const SurveyForm = () => {
  const { formId } = useParams();

  const navigate = useNavigate();

  const { data: form, isFetching: isLoadingForm } = useGetSurveyQuery(formId || '', {
    refetchOnMountOrArgChange: true,
  });

  const [createSurveyAnswer, { isLoading: isSaving }] = useCreateSurveyAnswerMutation();
  const [page, setPage] = useState(0);
  const [survey, setSurvey] = useState<Model>(new Model({}));
  const [localStorageFormData, setLocalStorageFormData] = useLocalStorage<string | null>(
    formId || '',
    '',
  );
  const localStorageSurveyData = localStorageFormData ? JSON.parse(localStorageFormData) : {};

  useEffect(() => {
    if (form) {
      setSurvey(new Model(form.content));
    }
  }, [form]);

  useEffect(() => {
    survey.onComplete.add(async (sender) => {
      if (not(survey)) return;

      try {
        await createSurveyAnswer({
          surveyId: form!.id,
          answer: sender.data,
        }).unwrap();

        setLocalStorageFormData(null);
      } catch (error) {
        showToastErrorMessage('There was an error trying to save the results of the filled form');
      } finally {
        if (not(form?.content?.completedHtml)) {
          navigate('/forms');
        }
      }
    });

    return () => {
      survey.onComplete.clear();
    };
  }, [form, survey, createSurveyAnswer, navigate, formId, setLocalStorageFormData]);

  useEffect(() => {
    setPage(localStorageSurveyData.pageNo || 0);
  }, [localStorageFormData]);

  if (isLoadingForm) {
    return (
      <Spinner
        withBackdrop
        fallbackText="Loading form..."
      />
    );
  }

  if (not(form)) {
    navigate('/forms');
    showToastErrorMessage('There was an error trying to load the form');

    return null;
  }

  survey.onCurrentPageChanged.add((_, options) => {
    setPage(options.newCurrentPage.visibleIndex);
  });

  const handleSurveyLocalStorageSave = (survey: Model) => {
    const { data } = survey;
    data.pageNo = survey.currentPageNo;

    setLocalStorageFormData(JSON.stringify(data));
  };

  survey.onValueChanged.add(handleSurveyLocalStorageSave);
  survey.onCurrentPageChanged.add(handleSurveyLocalStorageSave);
  survey.css = navigationCSS;
  if (Object.keys(localStorageSurveyData).length) {
    survey.data = localStorageSurveyData;
  } else {
    survey.data = {};
  }

  return (
    <>
      {isSaving && (
        <Spinner
          withBackdrop
          fallbackText="Saving results of the filled form..."
        />
      )}

      <div className="flex flex-col gap-y-4 mt-4 w-full">
        <SurveyNavigation
          form={form}
          survey={survey}
          page={page}
          setLocalStorageFormData={setLocalStorageFormData}
          withCompleteButton
        />

        <Survey
          currentPageNo={page}
          model={survey}
        />
      </div>
    </>
  );
};

export { SurveyForm };
