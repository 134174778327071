import { ButtonHTMLAttributes, forwardRef, PropsWithChildren } from 'react';
import { match } from 'ts-pattern';
import clsx from 'clsx';

import { Icon, Size as IconSize } from 'src/shared/ui/icon';

import { Color, Size } from './iconButton.types';

type IconButtonProps = PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> & {
  color?: Color;
  size?: Size;
  iconSize?: Size;
  iconClassName?: string;
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      children,
      className,
      color = 'basic',
      size = 'md',
      iconSize = size,
      iconClassName,
      disabled,
      ...props
    },
    ref,
  ) => {
    const commonClasses = clsx(
      `inline-flex justify-center items-center text-center 
    text-textColor-primary hover:text-textColor-primary 
    font-bold rounded-lg transition-all duration-200`,
      disabled && 'cursor-default',
    );

    const sizeClasses = match(size)
      .with('none', () => 'p-[0px]')
      .with('md', () => 'p-[8px]')
      .with('lg', () => 'p-[10px]')
      .otherwise(() => '');

    const content = match(color)
      .with('basic', () => (
        <button
          ref={ref}
          type="button"
          {...props}
          className={clsx(
            commonClasses,
            sizeClasses,
            `bg-bgColor-card
             hover:bg-bgColor-card-hover
             active:bg-bgColor-card-active`,
            disabled && 'hover:bg-bgColor-card active:bg-bgColor-card',
            className,
          )}
          disabled={disabled}
        >
          <Icon
            icon={children}
            color="dark"
            size={iconSize as IconSize}
            className={iconClassName}
          />
        </button>
      ))
      .with('primary', () => (
        <button
          ref={ref}
          type="button"
          {...props}
          className={clsx(
            commonClasses,
            sizeClasses,
            `bg-brandingColor-primary-gradient
           hover:bg-brandingColor-primary-gradient-hover
           active:bg-brandingColor-primary-gradient-active
           transition-colors`,
            disabled &&
              'hover:bg-brandingColor-primary-gradient active:bg-brandingColor-primary-gradient',

            className,
          )}
          disabled={disabled}
        >
          <Icon
            icon={children}
            color="light"
            size={size as IconSize}
            className={iconClassName}
          />
        </button>
      ))
      .with('secondary', () => (
        <button
          ref={ref}
          type="button"
          {...props}
          className={clsx(
            commonClasses,
            sizeClasses,
            'bg-bgColor-main hover:bg-bgColor-main-hover active:bg-bgColor-main-active',
            disabled && 'hover:bg-bgColor-main active:bg-bgColor-main',
            className,
          )}
          disabled={disabled}
        >
          <Icon
            icon={children}
            color="dark"
            size={iconSize as IconSize}
            className={iconClassName}
          />
        </button>
      ))
      .otherwise(() => (
        <button
          ref={ref}
          type="button"
          {...props}
          className={clsx(commonClasses, sizeClasses, className)}
          disabled={disabled}
        >
          {children}
        </button>
      ));

    return content;
  },
);

IconButton.displayName = 'IconButton';

export { IconButton };
export type { IconButtonProps };
