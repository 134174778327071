import { DateRangeType } from 'src/shared/ui/datepicker/types';

import { TicketStatus } from './tickets';

type DefaultEntity = {
  id: string;
  createDate: string;
  modifiedDate: string;
};

type PersonEntity = {
  id: string;
  firstName: string;
  lastName: string;
  providerPersonnelEmail?: string;
  providerPersonnelID: string;
  providerPersonnelPhone: string;
  createdBy?: string;
  modifiedBy?: string;
  createDate: string;
  modifiedDate: string;
  providerRoleMatrix: null;
  jobTitle: string;
  typicalWorkClassification?: string;
} & DefaultEntity;

type LaborEntity = {
  PeopleWorkRecordID: string;
  ProviderPersonnel?: PersonEntity;
  ContractRateSheet: ContractRateSheet;
  AcceptWork: string;
  Provider: Provider;
};

type TicketEntity = {
  id: number;
  projectName: string;
  job: string;
  owner: Owner;
  ownerLocation?: OwnerLocation;
  jobCategory: Category;
  po?: string;
  wo?: string;
  ownerContract: ContractList[][0];
  ownerContact?: Owner['OwnerPersonnel'][0];
  ownerRepresentative?: Owner['OwnerPersonnel'][0];
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  duration: string;
  shift: string;
  lunchAllowed: string;
  scheduledLunch: string;
  workNotes: string;
  createdBy: string;
  modifiedBy: string;
  createDate: string;
  modifiedDate: string;
  crewLeader: LaborEntity | null;
  supervisors: PersonEntity[];
  employees: PersonEntity[];
  labor: LaborEntity[];
  equipment: EquipmentEntity[];
  status: TicketStatus;
  thisShiftTimeOnYard?: string;
  thisShiftBaseTime?: string;
  JELL?: JELL;
  ticketNumber: string;
} & DefaultEntity;

type CopyTicket = {
  id: string;
  projectName: string;
  job: string;
  owner: Owner;
  ownerLocation: OwnerLocation;
  jobCategory: Category;
  po?: string;
  wo?: string;
  ownerContract: ContractList[][0];
  ownerContact?: Owner['OwnerPersonnel'][0];
  ownerRepresentative?: Owner['OwnerPersonnel'][0];
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  thisShiftTimeOnYard?: string;
  shift: string;
  lunchAllowed: string;
  scheduledLunch: string;
  workNotes: string;
  createdBy: string;
  modifiedBy: string;
  createDate: string;
  modifiedDate: string;
  crewLeader: LaborEntity | null;
  supervisors: PersonEntity[];
  employees: string[];
  equipment: string[];
  optimisticPeople: PersonEntity[];
  optimisticEquipment: EquipmentEntity[];
  JELL: JELL;
  duration: string;
};

type Category = {
  Id: string;
  Category: string;
};

type JobEntity = {
  id: string;
  projectName: string;
  owner: Owner;
  ownerLocation: OwnerLocation;
  ownerContract: ContractList[][0];
  ownerContact?: Owner['OwnerPersonnel'][0];
  ownerRepresentative?: Owner['OwnerPersonnel'][0];
  department: ProviderDivision;
  jobCategory: Category;
  po?: string;
  wo?: string;
  serviceLine: ServiceLine;
  serviceType?: ServiceLine['ServiceTypes'][0];
  requestedStartDate: DateRangeType['startDate'];
  requestedCompleteDate: DateRangeType['endDate'];
  startTime: string;
  endTime: string;
  isLunchAllowed: 'Yes' | 'No';
  createdBy: string;
  createDate: string;
  modifiedBy: string;
  modifiedDate: string;
  workRequestNotes: string;
  tickets: TicketEntity[];
  shiftType?: string;
  row?: number;
  sro?: string;
  JELL: JELL;
  isJELLEnforced: boolean;
  defaultScheduledStartTime?: string;
  defaultScheduledDuration?: string;
  defaultScheduledTimeOnYard?: string;
  defaultScheduledBaseTime?: string;
  jobHealth?: number;
} & DefaultEntity;

type EquipmentEntity = {
  UnitDescription: string;
  UnitNo: string;
  Type: string;
  AssetID: string;
  RequiresUnitNumber: string;
  UnitNumber: string;
  KITItemsRecordID: string;
  ContractRateSheet: ContractRateSheet;
  UOM: string;
  QTY: string;
  RentalUnitNumber: string;
  IsRental: boolean;
  type: EquipmentTabType;
} & DefaultEntity;

type MostUsedEquipment = {
  Type: string;
  TypeCount: number;
};

type MostUsedJobTitle = {
  JobTitle: string;
  JobTitleCount: number;
};

type AccessEntity = {
  ProviderPersonnelEmail: string;
  RoleID: string;
  ProviderID: string;
  ProviderPersonnel: {
    FirstName: string;
    LastName: string;
  };
  SystemAdmin: 'Yes' | 'No';
};

type Owner = {
  OwnerID: string;
  OwnerName: string;
  OwnerPersonnel: {
    OwnerPersonnelEmail: string;
    FirstName: string;
    LastName: string;
  }[];
};

type ContractList = {
  RateSheetID: string;
  RateSheetDescription: string;
  MasterorCustomerContract: string;
  OwnerID?: string;
  OwnerLocationID?: string;
};

type OwnerLocation = {
  OwnerID: string;
  OwnerLocationID: string;
  OwnerLocation: string;
  LocationTimezone: string;
  ContractList: ContractList[];
};

type ServiceLine = {
  ServiceLineID: string;
  ServiceLineDescription: string;
  ServiceTypes: {
    ServiceTypeID: string;
    ServiceTypeDescription: string;
  }[];
};

type ProviderDivision = {
  ProviderDivisionID: string;
  ProviderDivisionName: string;
  ProviderBranchID: string;
};

type ProviderBranch = {
  BranchID: string;
  BranchName: string;
};

type JELL = {
  AdditionalKitDescription1: string;
  EquipmentKitID: string;
  Contract: string;
};

type ContractRateSheet = {
  ItemCodeCategoryName: string;
  ItemCodeCategoryID: string;
  CustomItemDescription: string;
  Itemcode: string;
  RequiresUnitNumber: string;
  UOM: string;
  QTY: string;
  RentalItemCode: string;
  RateSheetItemID: string;
  BillType: string;
};

type EquipmentJELL = {
  KITItemsRecordID: string;
  EquipmentKITListID: string;
  ExtraQuantity: string;
  ContractRateSheet?: ContractRateSheet;
  RequiresUnitNumber: 'Yes' | 'No';
  UOM: string;
  QTY: string;
  UnitNumber: string;
  RentalUnitNumber: string;
  IsRental: string;
};

type LaborJELLItem = {
  LaborBuilderID: number;
  ProviderID: string;
  OwnerID: number;
  OwnerLocationID: number;
  RateSheetID: number;
  EquipmentKITListID: number;
  RateSheetItemID: number;
  DisplayOrder: number;
  Quantity: number;
  ScheduledWorkID: number;
  ContractRateSheet: ContractRateSheet;
};

type LaborJELLDetail = {
  ContractRateSheet?: ContractRateSheet;
  JobRole: string;
  PeopleWorkRecordID: string;
  ProviderPersonnelID: string;
  QTY: string;
  RateSheetID: string;
  RateSheetItemID: string;
  UOM: string;
  WorkClassificationstring: string;
  ProviderPersonnel?: {
    FirstName: string;
    LastName: string;
  };
};

type JELLItems = {
  requiredEquipment: EquipmentJELL[];
  requiredLabor: LaborJELLItem[];
};

type JELLDetails = {
  requiredEquipment: EquipmentJELL[];
  requiredLabor: LaborJELLDetail[];
};

type Provider = {
  LaborAcknowledgementDue: number;
  FileRootPath: string;
};

type ProviderPortal = {
  Id: string;
  PageTitle: string;
  Favicon: string;
  BannerLogo: string;

  ProviderPortalSections: ProviderPortalSection[];
};

type ProviderPortalSection = {
  Id: string;
  DisplayOrder: number;
  Title: string;
  ProviderPortalSectionItems: ProviderPortalSectionItem[];
};

type ProviderPortalSectionItem = {
  Id: string;
  Title: string;
  Icon: string;
  Link: string;
  HoverText: string;
  DisplayOrder: number;
};

type PageSize = 15 | 30 | 50;

type PaginationType = {
  currentPage: number;
  pageSize: PageSize;
  total: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: PageSize) => void;
};

type SurveySubcategory = {
  id: string;
  name: string;
  surveys: SurveyEntity[];
};

type SurveyCategory = {
  id: string;
  name: string;
  subcategories: SurveySubcategory[];
};

type SurveyNotificationSubscription = {
  id: string;
  notificationId: string;
  userId: string;
};

type SurveyNotification = {
  id: string;
  name: string;
  subscriptions: SurveyNotificationSubscription[];
  emailTemplateId: string;
};

type SurveyNotificationTemplate = {
  id: string;
  name: string;
};

type SurveyEntity = {
  id: string;
  name: string;
  author: {
    id: string;
  };
  content: any;
  answers: SurveyAnswerEntity[];
  category: SurveyCategory;
  subcategory: SurveySubcategory;
  notifications: SurveyNotification[];
  createdAt: string;
  updatedAt: string;
};

type SurveyAnswerEntity = {
  id: string;
  answer: any;
  author: {
    id: string;
  };
  surveyId: string;
  createdAt: string;
  updatedAt: string;
};

type Error = {
  status: number;
};

const EquipmentTab = {
  owned: 'owned',
  rental: 'rental',
} as const;

type EquipmentTabType = keyof typeof EquipmentTab;

enum Role {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  SurveyReadonly = 'SurveyReadonly',
  SurveyUser = 'SurveyUser',
  User = 'User',
  Inactive = 'Inactive',
}

enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export type {
  JobEntity,
  TicketEntity,
  PersonEntity,
  MostUsedJobTitle,
  EquipmentEntity,
  MostUsedEquipment,
  ContractRateSheet,
  DefaultEntity,
  AccessEntity,
  Owner,
  OwnerLocation,
  ServiceLine,
  ProviderDivision,
  ProviderBranch,
  Category,
  CopyTicket,
  JELL,
  JELLItems,
  EquipmentJELL,
  LaborJELLItem,
  LaborJELLDetail,
  JELLDetails,
  ProviderPortal,
  ContractList,
  PaginationType,
  PageSize,
  LaborEntity,
  SurveyEntity,
  SurveyAnswerEntity,
  Provider,
  Error,
  EquipmentTabType,
  SurveyCategory,
  SurveySubcategory,
  SurveyNotificationTemplate,
  SurveyNotification,
};

export { Role, SortOrder, EquipmentTab };
export type { ProviderPortalSection, ProviderPortalSectionItem };
