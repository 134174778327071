import { Dispatch, FC, MouseEvent, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Role, SurveyCategory, SurveySubcategory } from 'src/shared/types';
import { Card } from 'src/shared/ui/card';
import { Typography } from 'src/shared/ui/typography';
import { clsx } from 'src/shared/utils';
import { Button } from 'src/shared/ui/button';
import { ReactComponent as MoreVerticalIcon } from 'src/assets/icons/filled/menus/more-vertical.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/outlined/edit/edit.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/outlined/edit/trash.svg';
import { CardMenuItem } from 'src/shared/ui/schedulerBoard/ui/cardMenuItem';
import { Icon } from 'src/shared/ui/icon';
import { IconButton } from 'src/shared/ui/iconButton';
import { ADMIN_ROLES } from 'src/shared/constants';
import { selectCurrentUser } from 'src/store/slices';
import { DropDown, DropDownItem } from 'src/shared/ui/dropDown';

import { CreateOrUpdateSurveySubcategoryModal } from '../../CreateOrUpdateSurveySubcategoryModal';

import { SurveyFormCard } from './SurveyFormCard';
import { DeleteSubcategoryModal } from './DeleteSubcategoryModal';

type SurveySubcategoryCardProps = {
  subcategory: SurveySubcategory;
  category: SurveyCategory;
  lastCard: boolean;
};

const SurveySubcategoryCard: FC<SurveySubcategoryCardProps> = ({
  subcategory,
  category,
  lastCard,
}) => {
  const navigate = useNavigate();

  const user = useSelector(selectCurrentUser);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const isAdmin = ADMIN_ROLES.includes(user?.ProviderRoleMatrix?.userRole as Role);

  const openFormCreator = () => {
    navigate(`/forms/create/${subcategory.id}`);
  };

  const openSubcategoryRelatedModal = (
    e: MouseEvent<HTMLButtonElement>,
    cb: Dispatch<SetStateAction<boolean>>,
  ) => {
    e.stopPropagation();
    cb(true);
  };

  return (
    <>
      <Card
        key={subcategory.id}
        title={
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-x-2">
              <Typography
                variant="p1"
                fontWeight="bold"
              >
                {subcategory.name}
              </Typography>

              {isAdmin && (
                <DropDown
                  options={{ placement: 'bottom-start' }}
                  config={{
                    itemsElementClassName: clsx(
                      'shadow-[0px_2px_66px_-10px_#0000000F]',
                      'w-[200px]',
                      'rounded-lg',
                      'bg-bgColor-card',
                    ),
                  }}
                  renderElement={() => (
                    <IconButton
                      color="basic"
                      size="md"
                      iconSize="md"
                      iconClassName="fill-textColor-tertiary"
                    >
                      <MoreVerticalIcon />
                    </IconButton>
                  )}
                  items={
                    [
                      isAdmin
                        ? {
                            value: 'edit',
                            label: (
                              <CardMenuItem
                                onClick={(e) => openSubcategoryRelatedModal(e, setIsEditModalOpen)}
                                startIcon={
                                  <Icon
                                    className="fill-textColor-tertiary"
                                    icon={<EditIcon />}
                                  />
                                }
                                titleClassName="text-textColor-tertiary"
                              >
                                Edit
                              </CardMenuItem>
                            ),
                          }
                        : null,
                      isAdmin
                        ? {
                            value: 'delete',
                            label: (
                              <CardMenuItem
                                onClick={(e) =>
                                  openSubcategoryRelatedModal(e, setIsDeleteModalOpen)
                                }
                                startIcon={
                                  <Icon
                                    className="fill-semanticColor-danger"
                                    icon={<TrashIcon />}
                                  />
                                }
                                titleClassName="text-semanticColor-danger"
                              >
                                Delete
                              </CardMenuItem>
                            ),
                          }
                        : null,
                    ].filter(Boolean) as DropDownItem[]
                  }
                />
              )}
            </div>

            {isAdmin && (
              <Button
                color="primary"
                className="w-fit"
                onClick={openFormCreator}
              >
                Create form
              </Button>
            )}
          </div>
        }
        isAccordion={!!subcategory.surveys.length}
        iconPosition="left"
        className={clsx('min-h-[72px]', lastCard && 'mb-4')}
      >
        <div
          className={clsx(
            'flex flex-wrap justify-between gap-y-4',
            !subcategory.surveys.length && 'hidden',
          )}
        >
          {subcategory.surveys.map((survey) => (
            <SurveyFormCard
              key={survey.id}
              survey={survey}
              category={category}
            />
          ))}
        </div>
      </Card>

      <CreateOrUpdateSurveySubcategoryModal
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        category={category}
        subcategory={subcategory}
      />

      <DeleteSubcategoryModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        subcategory={subcategory}
      />
    </>
  );
};

export { SurveySubcategoryCard };
