import { api } from 'src/store/api/api';
import { SurveySubcategory } from 'src/shared/types';

import { surveyCategoryApi } from './survey-category';

export type CreateUpdateSubcategory = {
  name: string;
  categoryId: string;
  id?: string;
};

const surveySubcategoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSurveySubcategories: builder.query<SurveySubcategory[], ''>({
      query: () => ({
        url: '/survey-subcategories',
        method: 'GET',
      }),
      providesTags: ['SurveySubcategory'],
      transformResponse: (response: { data: SurveySubcategory[] }) => response.data,
    }),
    getSurveySubcategory: builder.query<SurveySubcategory, string>({
      query: (id) => ({
        url: `/survey-subcategories/${id}`,
        method: 'GET',
      }),
      providesTags: ['SurveySubcategory'],
      transformResponse: (response: { data: SurveySubcategory }) => response.data,
    }),
    createSurveySubcategory: builder.mutation<SurveySubcategory, CreateUpdateSubcategory>({
      query: (body) => ({
        url: '/survey-subcategories',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SurveySubcategory', 'SurveyCategory'],
    }),
    copySurveySubcategory: builder.mutation<SurveySubcategory, { surveyId: string }>({
      query: (body) => ({
        url: '/survey-subcategories/copy',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SurveySubcategory', 'SurveyCategory'],
    }),
    editSurveySubcategory: builder.mutation<SurveySubcategory, CreateUpdateSubcategory>({
      query: (body) => ({
        url: `/survey-subcategories`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['SurveySubcategory', 'SurveyCategory'],
    }),
    deleteSurveySubcategory: builder.mutation<SurveySubcategory, string>({
      query: (id) => ({
        url: `/survey-subcategories/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { queryFulfilled, dispatch }) {
        dispatch(
          surveyCategoryApi.util.updateQueryData('getSurveyCategories', '', (categories) => {
            const category = categories.find((category) =>
              category.subcategories.find((subcategory) => subcategory.id === id),
            );

            if (!category) {
              return;
            }

            category.subcategories.splice(
              category.subcategories.findIndex((subcategory) => subcategory.id === id),
              1,
            );
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          dispatch(api.util.invalidateTags(['SurveyCategory']));
        }
      },
    }),
  }),
});

export const {
  useGetSurveySubcategoriesQuery,
  useGetSurveySubcategoryQuery,
  useCreateSurveySubcategoryMutation,
  useEditSurveySubcategoryMutation,
  useDeleteSurveySubcategoryMutation,
} = surveySubcategoryApi;
