import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'src/shared/ui/button';
import { Typography } from 'src/shared/ui/typography';
import { ReactComponent as PlusIcon } from 'src/assets/icons/filled/edit/plus.svg';
import { useGetSurveyCategoriesQuery } from 'src/store/api/survey-category';
import { Role, SurveyCategory } from 'src/shared/types';
import { Skeleton } from 'src/shared/ui/skeleton';
import { ADMIN_ROLES } from 'src/shared/constants';
import { selectCurrentUser } from 'src/store/slices';
import { not } from 'src/shared/utils';

import { CreateOrUpdateSurveyCategoryModal, SurveyCategoryCard } from './Features';

type SurveyCategoriesSidebarProps = {
  expandedCategory: SurveyCategory | null;
  setExpandedCategory: Dispatch<SetStateAction<SurveyCategory | null>>;
};

const SurveyCategoriesSidebar: FC<SurveyCategoriesSidebarProps> = ({
  expandedCategory,
  setExpandedCategory,
}) => {
  const { data: categories = [], isFetching } = useGetSurveyCategoriesQuery('');

  const user = useSelector(selectCurrentUser);

  const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] = useState(false);

  const isAdmin = ADMIN_ROLES.includes(user?.ProviderRoleMatrix?.userRole as Role);

  const createCategory = () => {
    setIsCreateCategoryModalOpen(true);
  };

  return (
    <>
      <div className="flex flex-col h-full min-w-[316px] px-6 py-8 gap-y-8 bg-white">
        <div className="flex flex-col gap-y-8">
          <Typography variant="h1">Categories</Typography>

          {isAdmin && (
            <Button
              color="primary"
              className="w-[195px]"
              endIcon={<PlusIcon />}
              onClick={createCategory}
              size="lg"
              disabled={isFetching}
            >
              Create Category
            </Button>
          )}
        </div>

        <div className="flex flex-col gap-y-4 overflow-y-scroll">
          {isFetching
            ? Array.from({ length: 5 }).map((_, i) => (
                <Skeleton
                  className="h-[56px]"
                  // eslint-disable-next-line
                  key={i}
                />
              ))
            : categories.map((category) => (
                <SurveyCategoryCard
                  key={category.id}
                  category={category}
                  expandedCategory={expandedCategory}
                  setExpandedCategory={setExpandedCategory}
                />
              ))}
        </div>
      </div>

      <CreateOrUpdateSurveyCategoryModal
        isOpen={isCreateCategoryModalOpen}
        setIsOpen={setIsCreateCategoryModalOpen}
      />
    </>
  );
};

export { SurveyCategoriesSidebar };
