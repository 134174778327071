import { FC, useEffect } from 'react';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Home,
  Main,
  Auth,
  Reports,
  Dashboard,
  Forms,
  SurveyForm,
  SurveyFormCreator,
  SurveyAnswers,
  SurveyAnswer,
  EditSurveyAnswer,
  Notifications,
} from 'src/pages';
import { Role } from 'src/shared/types';
import { Layout } from 'src/shared/ui/layout';
import { selectCurrentUser } from 'src/store/slices';
import { getAzureUrl, not } from 'src/shared/utils';
import { useGetPortalProviderSettingsQuery, useLogoutMutation } from 'src/store/api';
import { useGetProviderQuery } from 'src/store/api/provider';

import { ADMIN_ROLES } from './shared/constants';

type ProtectedRouteProps = {
  withSidebar?: boolean;
  withHeader?: boolean;
  withHeaderFilters?: boolean;
  withHeaderCollapseAll?: boolean;
  withHeaderWeekNavigation?: boolean;
  withHeaderToday?: boolean;
  withHeaderSearch?: boolean;
  withPreloader?: boolean;
  extraAllowedRoles?: Role[];
  forbiddenRoles?: Role[];
  fallbackUrl?: string;
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  withPreloader = true,
  withSidebar = true,
  withHeader = true,
  withHeaderFilters = true,
  withHeaderCollapseAll = true,
  withHeaderWeekNavigation = true,
  withHeaderSearch = true,
  withHeaderToday = true,
  // allow any user to visit any route until we implement a new permissions system
  extraAllowedRoles = Object.values(Role),
  forbiddenRoles = [],
  fallbackUrl = '/',
}) => {
  const [logout] = useLogoutMutation();

  const user = useSelector(selectCurrentUser);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;
  const isUser = user?.ProviderRoleMatrix?.userRole === Role.User;

  const allowedRoles = [...ADMIN_ROLES, ...extraAllowedRoles].filter(
    (role) => !forbiddenRoles.includes(role),
  );

  if (isUser) {
    logout({});
    return <Navigate to="/auth" />;
  }

  if (not(user?.accessToken)) {
    return <Navigate to="/auth" />;
  }

  if (not(allowedRoles.includes(user?.ProviderRoleMatrix?.userRole as Role))) {
    return <Navigate to={fallbackUrl} />;
  }

  return (
    <Layout
      withSidebar={withSidebar && not(isReadonly)}
      withHeader={withHeader}
      withPreloader={withPreloader}
      withHeaderFilters={withHeaderFilters}
      withHeaderCollapseAll={withHeaderCollapseAll}
      withHeaderWeekNavigation={withHeaderWeekNavigation}
      withHeaderSearch={withHeaderSearch}
      withHeaderToday={withHeaderToday}
    >
      <Outlet />
    </Layout>
  );
};

const AdminRoute: FC<ProtectedRouteProps> = ({
  withPreloader = true,
  withSidebar = true,
  withHeader = true,
  withHeaderFilters = true,
  withHeaderCollapseAll = true,
  withHeaderWeekNavigation = true,
  withHeaderSearch = true,
  withHeaderToday = true,
  fallbackUrl = '/',
  extraAllowedRoles = [],
}) => {
  const user = useSelector(selectCurrentUser);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  const allowedRoles = [...ADMIN_ROLES, ...extraAllowedRoles];

  if (not(user?.accessToken)) {
    return <Navigate to="/auth" />;
  }

  if (not(allowedRoles.includes(user?.ProviderRoleMatrix?.userRole as Role))) {
    return <Navigate to={fallbackUrl} />;
  }

  return (
    <Layout
      withSidebar={withSidebar && not(isReadonly)}
      withHeader={withHeader}
      withPreloader={withPreloader}
      withHeaderFilters={withHeaderFilters}
      withHeaderCollapseAll={withHeaderCollapseAll}
      withHeaderWeekNavigation={withHeaderWeekNavigation}
      withHeaderSearch={withHeaderSearch}
      withHeaderToday={withHeaderToday}
    >
      <Outlet />
    </Layout>
  );
};

const FormsRoute: FC<Pick<ProtectedRouteProps, 'forbiddenRoles'>> = (props) => {
  return (
    <ProtectedRoute
      extraAllowedRoles={[Role.SurveyUser, Role.SurveyReadonly]}
      withPreloader={false}
      withSidebar={false}
      withHeaderCollapseAll={false}
      withHeaderToday={false}
      withHeaderFilters={false}
      withHeaderWeekNavigation={false}
      withHeaderSearch={false}
      fallbackUrl="/forms"
      {...props}
    />
  );
};

const AdminFormsRoute: FC<Pick<ProtectedRouteProps, 'extraAllowedRoles'>> = (props) => {
  return (
    <AdminRoute
      withPreloader={false}
      withSidebar={false}
      withHeaderCollapseAll={false}
      withHeaderToday={false}
      withHeaderFilters={false}
      withHeaderWeekNavigation={false}
      withHeaderSearch={false}
      fallbackUrl="/forms"
      {...props}
    />
  );
};

const router = createBrowserRouter([
  {
    path: '/auth',
    element: <Auth />,
  },
  {
    path: '/',
    element: (
      <ProtectedRoute
        extraAllowedRoles={Object.values(Role)}
        withPreloader={false}
        withSidebar={false}
        withHeader
        withHeaderCollapseAll={false}
        withHeaderFilters={false}
        withHeaderToday={false}
        withHeaderWeekNavigation={false}
        withHeaderSearch={false}
      />
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
    ],
  },
  {
    path: '/scheduler',
    element: <ProtectedRoute />,
    children: [
      {
        index: true,
        element: <Main />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute
        withPreloader={false}
        withSidebar={false}
        withHeaderCollapseAll={false}
        withHeaderToday={false}
      />
    ),
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
    ],
  },
  {
    path: '/forms',
    element: <FormsRoute />,
    children: [
      {
        index: true,
        element: <Forms />,
      },
    ],
  },
  {
    path: '/notifications',
    element: (
      <AdminRoute
        withHeaderCollapseAll={false}
        withHeaderSearch={false}
        withHeaderToday={false}
        withHeaderWeekNavigation={false}
        withSidebar={false}
        withHeaderFilters={false}
        withPreloader={false}
      />
    ),
    children: [
      {
        index: true,
        element: <Notifications />,
      },
    ],
  },
  {
    path: '/forms/create/:subcategoryId',
    element: <AdminFormsRoute />,
    children: [
      {
        index: true,
        element: <SurveyFormCreator type="create" />,
      },
    ],
  },
  {
    path: '/forms/:formId',
    element: <FormsRoute forbiddenRoles={[Role.SurveyReadonly]} />,
    children: [
      {
        index: true,
        element: <SurveyForm />,
      },
    ],
  },
  {
    path: '/forms/:formId/answers',
    element: <FormsRoute />,
    children: [
      {
        index: true,
        element: <SurveyAnswers />,
      },
    ],
  },
  {
    path: '/forms/:formId/edit',
    element: <AdminFormsRoute />,
    children: [
      {
        index: true,
        element: <SurveyFormCreator type="edit" />,
      },
    ],
  },
  {
    path: '/forms/:formId/answers/:answerId',
    element: <FormsRoute />,
    children: [
      {
        index: true,
        element: <SurveyAnswer />,
      },
    ],
  },
  {
    path: '/forms/:formId/answers/:answerId/edit',
    element: <AdminFormsRoute extraAllowedRoles={[Role.SurveyUser]} />,
    children: [
      {
        index: true,
        element: <EditSurveyAnswer />,
      },
    ],
  },
  {
    path: '/reports',
    element: (
      <ProtectedRoute
        withPreloader={false}
        withSidebar={false}
        withHeaderCollapseAll={false}
        withHeaderToday={false}
        withHeaderWeekNavigation={false}
        withHeaderSearch={false}
      />
    ),
    children: [
      {
        index: true,
        element: <Reports />,
      },
    ],
  },
]);

const Router: FC = () => {
  const { data: configuration } = useGetPortalProviderSettingsQuery({});
  const { data: provider } = useGetProviderQuery('');

  useEffect(() => {
    // * Set favicon and title dynamically
    const existingFavicon = document.querySelector("link[rel*='icon']");

    const favicon = existingFavicon || (document.createElement('link') as HTMLLinkElement);

    if (existingFavicon) {
      document.head.removeChild(existingFavicon);
    }

    if (favicon instanceof HTMLLinkElement) {
      favicon.type = 'image/x-icon';
      favicon.rel = 'icon';
      favicon.href = configuration?.Favicon
        ? getAzureUrl(provider?.FileRootPath, configuration?.Favicon)
        : '';

      document.title = configuration?.PageTitle || 'Scheduling Board';

      document.head.appendChild(favicon);
    }
  }, [configuration, provider]);

  return <RouterProvider router={router} />;
};

export { Router };
