import React from 'react';
import { Question, Serializer, SvgRegistry, SurveyError } from 'survey-core';
import { localization } from 'survey-creator-core';
import { SurveyQuestionElementBase, ReactQuestionFactory } from 'survey-react-ui';

import 'survey-core/defaultV2.css';
import 'survey-creator-core/survey-creator-core.css';

import { Button } from 'src/shared/ui/button';
import { Typography } from 'src/shared/ui/typography';
import { ReactComponent as TargetIcon } from 'src/assets/icons/outlined/edit/target.svg';
import { not } from 'src/shared/utils';

const CUSTOM_TYPE = 'geolocation';

export const GEOLOCATION_QUESTION_TYPE = CUSTOM_TYPE;

export class QuestionGeolocationModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
}

Serializer.addClass(
  CUSTOM_TYPE,
  [],
  () => {
    return new QuestionGeolocationModel('');
  },
  'question',
);

export class SurveyQuestionGeolocation extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = { value: this.question.value };
  }

  componentDidMount() {
    if (!this.question.jsonObj.autoCapture) {
      return;
    }

    this.captureLocation();
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  get style() {
    return this.question.getPropertyValue('readOnly') || this.question.isDesignMode
      ? { pointerEvents: 'none' }
      : undefined;
  }

  setLocation = ({ latitude, longitude }) => {
    this.question.value = {
      latitude,
      longitude,
    };
  };

  /**
   * @param {string} text - The error message to throw.
   */
  throwValidationError(text) {
    const isAlreadyThrown = this.question.errors.some(({ text: textEntry }) => textEntry === text);

    if (isAlreadyThrown) {
      return;
    }

    this.question.errors.push(new SurveyError(text));
  }

  captureLocation() {
    if (not('geolocation' in navigator)) {
      const errorMessage = 'Geolocation is not available.';

      this.throwValidationError(errorMessage);
      console.error(errorMessage);

      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;

        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

        this.setLocation(position.coords);
      },
      (error) => {
        this.throwValidationError(error.message);
        console.error('Error getting geolocation:', error.message);
      },
    );
  }

  renderElement() {
    return (
      <div className="flex justify-between items-center">
        <Button
          color="primary"
          endIcon={<TargetIcon />}
          size="md"
          className="px-3"
          // eslint-disable-next-line react/jsx-no-bind
          onClick={this.captureLocation.bind(this)}
        >
          Capture Location
        </Button>

        {!!this.question.value && (
          <div className="flex items-center gap-x-3">
            <Typography
              variant="p1"
              fontWeight="bold"
            >
              {`Latitude: ${this.question.value.latitude}`}
            </Typography>

            <Typography
              variant="p1"
              fontWeight="bold"
            >
              {`Longitude: ${this.question.value.longitude}`}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return React.createElement(SurveyQuestionGeolocation, props);
});

const locale = localization.getLocale('');
locale.qt[CUSTOM_TYPE] = 'Geolocation';

SvgRegistry.registerIconFromSvg(
  CUSTOM_TYPE,
  '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M13 2C13 1.44772 12.5523 1 12 1C11.4477 1 11 1.44772 11 2V3.05493C6.82838 3.51608 3.51608 6.82838 3.05493 11H2C1.44772 11 1 11.4477 1 12C1 12.5523 1.44772 13 2 13H3.05493C3.51608 17.1716 6.82838 20.4839 11 20.9451V22C11 22.5523 11.4477 23 12 23C12.5523 23 13 22.5523 13 22V20.9451C17.1716 20.4839 20.4839 17.1716 20.9451 13H22C22.5523 13 23 12.5523 23 12C23 11.4477 22.5523 11 22 11H20.9451C20.4839 6.82838 17.1716 3.51608 13 3.05493V2ZM11 5.07089V6C11 6.55228 11.4477 7 12 7C12.5523 7 13 6.55228 13 6V5.07089C16.0657 5.5094 18.4906 7.93431 18.9291 11H18C17.4477 11 17 11.4477 17 12C17 12.5523 17.4477 13 18 13H18.9291C18.4906 16.0657 16.0657 18.4906 13 18.9291V18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18V18.9291C7.93431 18.4906 5.5094 16.0657 5.07089 13H6C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11H5.07089C5.5094 7.93431 7.93431 5.5094 11 5.07089ZM12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11Z" /></svg>',
);
