import { Dispatch, FC, FormEvent, SetStateAction, useEffect, useState } from 'react';

import { SurveyEntity, SurveyNotification, SurveySubcategory } from 'src/shared/types';
import { Button } from 'src/shared/ui/button';
import { Modal } from 'src/shared/ui/modal';
import { SelectInput, SelectInputItem } from 'src/shared/ui/selectInput';
import { Spinner } from 'src/shared/ui/spinner';
import { Typography } from 'src/shared/ui/typography';
import { not, showToastErrorMessage } from 'src/shared/utils';
import { useGetAuthPeopleQuery } from 'src/store/api';
import { useEditSurveyMutation } from 'src/store/api/survey';
import {
  useGetSurveyNotificationsQuery,
  useSubscribeSurveyForSurveyNotificationsMutation,
} from 'src/store/api/survey-notification';
import { useGetSurveyCategoriesQuery } from 'src/store/api/survey-category';

type SurveyFormDetailsModalProps = {
  form: SurveyEntity;
  categoryId: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const SurveyFormDetailsModal: FC<SurveyFormDetailsModalProps> = ({
  form,
  categoryId,
  isOpen,
  setIsOpen,
}) => {
  const { data: categories = [] } = useGetSurveyCategoriesQuery('');
  const { data: authPeople = [] } = useGetAuthPeopleQuery('');
  const { data: notifications = [] } = useGetSurveyNotificationsQuery('');

  const category = categories.find((category) => category.id === categoryId);

  const [updateSurvey, { isLoading: isUpdating }] = useEditSurveyMutation();
  const [subscribeSurveyForNotification, { isLoading: isSubscribing }] =
    useSubscribeSurveyForSurveyNotificationsMutation();

  const isSubmitting = isUpdating || isSubscribing;

  const [selectedSubcategory, setSelectedSubcategory] = useState<SurveySubcategory | null>(null);
  const [selectedNotification, setSelectedNotification] = useState<SurveyNotification | null>(null);

  const subcategory =
    category?.subcategories.find((subcategory) =>
      subcategory.surveys.find((survey) => survey.id === form.id),
    ) || null;

  const closeModal = () => {
    setIsOpen(false);
    setSelectedSubcategory(null);
    setSelectedNotification(null);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await updateSurvey({
        ...form,
        subcategoryId: selectedSubcategory!.id,
      }).unwrap();

      await subscribeSurveyForNotification({
        notificationIds: selectedNotification ? [selectedNotification.id] : [],
        surveyId: form.id,
      });
    } catch (error) {
      showToastErrorMessage(`There was an error trying to update ${form.name}`);
    } finally {
      closeModal();
    }
  };

  useEffect(() => {
    setSelectedSubcategory(subcategory);
    setSelectedNotification(form.notifications[0] ? form.notifications[0] : null);
  }, [category, subcategory, isOpen, authPeople, form.notifications]);

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={closeModal}
    >
      <form
        className="flex flex-col justify-between gap-y-6 w-[550px]"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-8">
          <Typography variant="h2">{form.name}</Typography>

          <div className="flex flex-col gap-y-4">
            <Typography variant="h3">{category?.name}</Typography>

            <SelectInput
              isRequired
              name="subcategory"
              value={selectedSubcategory?.name}
              label="Subcategory"
              placeholder="Select subcategory"
              onClear={() => setSelectedSubcategory(null)}
              items={
                category?.subcategories.map((subcategory) => ({
                  label: (
                    <SelectInputItem selected={selectedSubcategory?.name === subcategory.name}>
                      {subcategory.name}
                    </SelectInputItem>
                  ),
                  value: subcategory.name,
                  onClick: () => setSelectedSubcategory(subcategory),
                })) ?? []
              }
            />

            <div className="flex flex-col gap-y-3">
              <Typography variant="h3">Select Notifications</Typography>

              <SelectInput
                isRequired
                name="notifications"
                value={selectedNotification?.name}
                label="Notifications"
                placeholder="Select notifications"
                onClear={() => setSelectedNotification(null)}
                items={
                  notifications.map((notification) => ({
                    label: (
                      <SelectInputItem selected={selectedSubcategory?.name === notification.name}>
                        {notification.name}
                      </SelectInputItem>
                    ),
                    value: notification.name,
                    onClick: () => setSelectedNotification(notification),
                  })) ?? []
                }
              />
            </div>
          </div>
        </div>

        <div className="flex w-full justify-end gap-x-2">
          <Button
            variant="outlined"
            onClick={closeModal}
            disabled={isSubmitting}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            disabled={isSubmitting || not(selectedSubcategory)}
          >
            {isSubmitting ? <Spinner size="sm" /> : 'Update'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { SurveyFormDetailsModal };
