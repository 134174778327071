import { DOMAIN, FALLBACK_TENANT } from 'src/config';

export const getTenant = () => {
  const isProduction = process.env.NODE_ENV === 'production';

  const envMatcher = /-.*$/;

  const tenant = isProduction
    ? window.location.host.replace(DOMAIN, '').replace(envMatcher, '')
    : FALLBACK_TENANT;

  const env = isProduction ? window.location.host.replace(DOMAIN, '').split('-')[1] || null : null;

  return {
    tenant,
    env,
  };
};
