import { FC, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Model } from 'survey-core';

import { Role, SurveyCategory, SurveyEntity } from 'src/shared/types';
import { Typography } from 'src/shared/ui/typography';
import { clsx, not, showToastErrorMessage } from 'src/shared/utils';
import { ReactComponent as MoreVerticalIcon } from 'src/assets/icons/filled/menus/more-vertical.svg';
import { ReactComponent as ClipboardIcon } from 'src/assets/icons/outlined/edit/clipboard.svg';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/filled/files/download.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/outlined/edit/edit.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/outlined/edit/trash.svg';
import { ReactComponent as FillIcon } from 'src/assets/icons/outlined/misc/tabler-layout-sidebar-left-expand.svg';
import { ReactComponent as CopyIcon } from 'src/assets/icons/outlined/edit/copy.svg';
import { ReactComponent as FileTextIcon } from 'src/assets/icons/outlined/files/file-text.svg';
import { IconButton } from 'src/shared/ui/iconButton';
import { selectCurrentUser } from 'src/store/slices';
import { ADMIN_ROLES } from 'src/shared/constants';
import { useCopySurveyMutation } from 'src/store/api/survey';
import { createSurveyPDFModel } from 'src/pages/SurveyForm/utils';
import { DropDown, DropDownItem } from 'src/shared/ui/dropDown';
import { CardMenuItem } from 'src/shared/ui/schedulerBoard/ui/cardMenuItem';
import { Icon } from 'src/shared/ui/icon';
import { Spinner } from 'src/shared/ui/spinner';

import { DeleteFormModal } from '../../DeleteFormModal';

import { SurveyFormDetailsModal } from './SurveyFormDetailsModal';

type SurveyFormCardProps = {
  survey: SurveyEntity;
  category: SurveyCategory;
};

const SurveyFormCard: FC<SurveyFormCardProps> = ({ survey, category }) => {
  const navigate = useNavigate();

  const [isDeleteFormModalOpen, setIsDeleteFormModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [copySurvey, { isLoading: isCopyingForm }] = useCopySurveyMutation();

  const user = useSelector(selectCurrentUser);

  const isAdmin = ADMIN_ROLES.includes(user?.ProviderRoleMatrix?.userRole as Role);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  const deleteForm = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsDeleteFormModalOpen(true);
  };

  const openAnswersPage = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    navigate(`/forms/${survey.id}/answers`);
  };

  const openFillForm = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (isReadonly) {
      return;
    }

    navigate(`/forms/${survey.id}`);
  };

  const openEditForm = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    navigate(`/forms/${survey.id}/edit`);
  };

  const copyForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    try {
      await copySurvey({ surveyId: survey.id }).unwrap();
    } catch (error) {
      showToastErrorMessage(`There was an error trying to copy ${survey.name}`);
    }
  };

  const saveToPDF = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    const surveyModel = new Model(survey.content);
    createSurveyPDFModel(survey.content, surveyModel).save(survey.name);
  };

  const openDetailsModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setIsDetailsModalOpen(true);
  };

  return (
    <>
      <button
        type="button"
        key={survey.id}
        className="flex flex-col gap-y-1 py-4 px-6 w-[49%] bg-bgColor-ticketCard rounded-lg border transition-all duration-200 hover:border-brandingColor-primary-gradient"
        onClick={openFillForm}
      >
        <div className="flex items-center justify-between w-full">
          <Typography
            variant="p1"
            fontWeight="bold"
          >
            {survey.name}
          </Typography>

          <DropDown
            options={{ placement: 'bottom-start' }}
            config={{
              itemsElementClassName: clsx(
                'w-[200px]',
                'shadow-[0px_2px_66px_-10px_#0000000F]',
                'rounded-lg',
                'bg-bgColor-card',
              ),
            }}
            renderElement={() => (
              <IconButton
                color="basic"
                size="md"
                iconSize="md"
                iconClassName="fill-textColor-tertiary"
                className="bg-bgColor-ticketCard"
              >
                <MoreVerticalIcon />
              </IconButton>
            )}
            items={
              [
                {
                  value: 'answers',
                  label: (
                    <CardMenuItem
                      onClick={openAnswersPage}
                      startIcon={
                        <Icon
                          className="fill-textColor-tertiary"
                          icon={<ClipboardIcon />}
                        />
                      }
                      titleClassName="text-textColor-tertiary"
                    >
                      Survey Answers
                    </CardMenuItem>
                  ),
                },
                not(isReadonly)
                  ? {
                      value: 'fill',
                      label: (
                        <CardMenuItem
                          onClick={openFillForm}
                          startIcon={
                            <Icon
                              className="fill-white"
                              icon={<FillIcon />}
                            />
                          }
                          titleClassName="text-textColor-tertiary"
                        >
                          Fill
                        </CardMenuItem>
                      ),
                    }
                  : null,
                isAdmin
                  ? {
                      value: 'edit',
                      label: (
                        <CardMenuItem
                          onClick={openEditForm}
                          startIcon={
                            <Icon
                              className="fill-textColor-tertiary"
                              icon={<EditIcon />}
                            />
                          }
                          titleClassName="text-textColor-tertiary"
                        >
                          Edit
                        </CardMenuItem>
                      ),
                    }
                  : null,
                {
                  value: 'pdf',
                  label: (
                    <CardMenuItem
                      onClick={saveToPDF}
                      startIcon={
                        <Icon
                          className="fill-textColor-tertiary"
                          icon={<DownloadIcon />}
                        />
                      }
                      titleClassName="text-textColor-tertiary"
                    >
                      Export to PDF
                    </CardMenuItem>
                  ),
                },
                isAdmin
                  ? {
                      value: 'copy',
                      label: (
                        <CardMenuItem
                          onClick={copyForm}
                          startIcon={
                            <Icon
                              className="fill-textColor-tertiary"
                              icon={<CopyIcon />}
                            />
                          }
                          titleClassName="text-textColor-tertiary"
                        >
                          Copy
                        </CardMenuItem>
                      ),
                    }
                  : null,
                isAdmin
                  ? {
                      value: 'details',
                      label: (
                        <CardMenuItem
                          onClick={openDetailsModal}
                          startIcon={
                            <Icon
                              className="fill-textColor-tertiary"
                              icon={<FileTextIcon />}
                            />
                          }
                          titleClassName="text-textColor-tertiary"
                        >
                          Details
                        </CardMenuItem>
                      ),
                    }
                  : null,
                isAdmin
                  ? {
                      value: 'delete',
                      label: (
                        <CardMenuItem
                          onClick={deleteForm}
                          startIcon={
                            <Icon
                              className="fill-semanticColor-danger"
                              icon={<TrashIcon />}
                            />
                          }
                          titleClassName="text-semanticColor-danger"
                        >
                          Delete
                        </CardMenuItem>
                      ),
                    }
                  : null,
              ].filter(Boolean) as DropDownItem[]
            }
          />
        </div>
      </button>

      <DeleteFormModal
        form={survey}
        isOpen={isDeleteFormModalOpen}
        setIsOpen={setIsDeleteFormModalOpen}
      />

      {isCopyingForm && (
        <Spinner
          withBackdrop
          fallbackText={`Copying ${survey?.name} form...`}
        />
      )}

      <SurveyFormDetailsModal
        form={survey}
        categoryId={category.id}
        isOpen={isDetailsModalOpen}
        setIsOpen={setIsDetailsModalOpen}
      />
    </>
  );
};

export { SurveyFormCard };
