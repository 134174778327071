import { Dispatch, FC, MouseEvent, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'src/shared/ui/typography';
import { ReactComponent as PlusIcon } from 'src/assets/icons/filled/edit/plus.svg';
import { Role, SurveyCategory } from 'src/shared/types';
import { IconButton } from 'src/shared/ui/iconButton';
import { ReactComponent as MoreHorizontalIcon } from 'src/assets/icons/filled/menus/more-horizontal.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/outlined/edit/edit.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/outlined/edit/trash.svg';
import { ReactComponent as ChevronRightIcon } from 'src/assets/icons/outlined/chevrons/chevron-right.svg';
import { ReactComponent as ChevronDownIcon } from 'src/assets/icons/outlined/chevrons/chevron-down.svg';
import { clsx } from 'src/shared/utils';
import { DropDown, DropDownItem } from 'src/shared/ui/dropDown';
import { Icon } from 'src/shared/ui/icon';
import { CardMenuItem } from 'src/shared/ui/schedulerBoard/ui/cardMenuItem';
import { ADMIN_ROLES } from 'src/shared/constants';
import { selectCurrentUser } from 'src/store/slices';
import { useLocalStorage } from 'src/shared/hooks/useLocalStorage';

import { CreateOrUpdateSurveySubcategoryModal } from '../../CreateOrUpdateSurveySubcategoryModal';

import { CreateOrUpdateSurveyCategoryModal } from './CreateOrUpdateSurveyCategoryModal';
import { DeleteCategoryModal } from './DeleteCategoryModal';

type SurveyCategoryCardProps = {
  category: SurveyCategory;
  expandedCategory: SurveyCategory | null;
  setExpandedCategory: Dispatch<SetStateAction<SurveyCategory | null>>;
};

const SurveyCategoryCard: FC<SurveyCategoryCardProps> = ({
  category,
  expandedCategory,
  setExpandedCategory,
}) => {
  const user = useSelector(selectCurrentUser);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateSubcategoryModalOpen, setIsCreateSubcategoryModalOpen] = useState(false);
  const [expandedCategoryLocalStorage, setExpandedCategoryLocalStorage] = useLocalStorage(
    'expandedCategory',
    '{}',
  );

  const isAdmin = ADMIN_ROLES.includes(user?.ProviderRoleMatrix?.userRole as Role);

  const toggleExpandedCategory = (category: SurveyCategory) => {
    if (expandedCategory?.id === category.id) {
      setExpandedCategory(null);
      setExpandedCategoryLocalStorage('{}');
    } else {
      setExpandedCategory(category);
      setExpandedCategoryLocalStorage(JSON.stringify(category));
    }
  };

  const openCategoryRelatedModal = (
    e: MouseEvent<HTMLButtonElement>,
    cb: Dispatch<SetStateAction<boolean>>,
  ) => {
    e.preventDefault();
    cb(true);
  };

  return (
    <>
      <div
        key={category.id}
        className={clsx(
          'flex flex-col gap-y-2 p-2',
          category.id === expandedCategory?.id && 'bg-[#E8F0FF] rounded-lg',
        )}
      >
        <div className="flex justify-between gap-x-3">
          <button
            type="button"
            onClick={() => toggleExpandedCategory(category)}
            className={clsx('flex items-center w-full', !category.subcategories.length && 'pl-6')}
          >
            {!!category.subcategories.length && (
              <Icon
                icon={
                  expandedCategory?.id === category.id ? <ChevronDownIcon /> : <ChevronRightIcon />
                }
              />
            )}

            <Typography
              variant="p1"
              className="text-left"
            >
              {category.name}
            </Typography>
          </button>

          {isAdmin && (
            <DropDown
              options={{ placement: 'bottom-start' }}
              config={{
                itemsElementClassName: clsx(
                  'shadow-[0px_2px_66px_-10px_#0000000F]',
                  'w-[200px]',
                  'rounded-lg',
                  'bg-bgColor-card',
                ),
              }}
              renderElement={() => (
                <IconButton
                  color="basic"
                  size="md"
                  iconSize="md"
                  iconClassName="fill-textColor-tertiary"
                >
                  <MoreHorizontalIcon />
                </IconButton>
              )}
              items={
                [
                  isAdmin
                    ? {
                        value: 'edit',
                        label: (
                          <CardMenuItem
                            onClick={(e) => openCategoryRelatedModal(e, setIsEditModalOpen)}
                            startIcon={
                              <Icon
                                className="fill-textColor-tertiary"
                                icon={<EditIcon />}
                              />
                            }
                            titleClassName="text-textColor-tertiary"
                          >
                            Edit
                          </CardMenuItem>
                        ),
                      }
                    : null,
                  isAdmin
                    ? {
                        value: 'add-subcategory',
                        label: (
                          <CardMenuItem
                            onClick={(e) =>
                              openCategoryRelatedModal(e, setIsCreateSubcategoryModalOpen)
                            }
                            startIcon={
                              <Icon
                                className="fill-textColor-tertiary"
                                icon={<PlusIcon />}
                              />
                            }
                            titleClassName="text-textColor-tertiary"
                          >
                            Add Subcategory
                          </CardMenuItem>
                        ),
                      }
                    : null,
                  isAdmin
                    ? {
                        value: 'delete',
                        label: (
                          <CardMenuItem
                            onClick={(e) => openCategoryRelatedModal(e, setIsDeleteModalOpen)}
                            startIcon={
                              <Icon
                                className="fill-semanticColor-danger"
                                icon={<TrashIcon />}
                              />
                            }
                            titleClassName="text-semanticColor-danger"
                          >
                            Delete
                          </CardMenuItem>
                        ),
                      }
                    : null,
                ].filter(Boolean) as DropDownItem[]
              }
            />
          )}
        </div>

        {!!category.subcategories.length && expandedCategory?.id === category.id && (
          <div className="flex flex-col pl-8">
            {category.subcategories.map((subcategory) => (
              <div key={subcategory.id}>{subcategory.name}</div>
            ))}
          </div>
        )}
      </div>

      <CreateOrUpdateSurveyCategoryModal
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        category={category}
      />

      <CreateOrUpdateSurveySubcategoryModal
        isOpen={isCreateSubcategoryModalOpen}
        setIsOpen={setIsCreateSubcategoryModalOpen}
        category={category}
      />

      <DeleteCategoryModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        category={category}
      />
    </>
  );
};

export { SurveyCategoryCard };
