import { forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';

import { ReactComponent as ChevronUpIcon } from 'src/assets/icons/outlined/chevrons/chevron-up.svg';
import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/outlined/edit/close-circle.svg';
import { TextField, type TextFieldProps } from 'src/shared/ui/textField';
import { DropDown, DropDownProps } from 'src/shared/ui/dropDown';

import { IconButton } from '../iconButton';

type SelectInputProps = TextFieldProps & {
  items: DropDownProps['items'];
  onClear?: (name: string) => void;
};

const SelectInput = forwardRef<HTMLInputElement, SelectInputProps>(
  ({ items, isRequired = false, onClear, ...props }, ref) => {
    const [search, setSearch] = useState(typeof props.value === 'string' ? props.value : '');

    useEffect(() => {
      setSearch(typeof props.value === 'string' ? props.value : '');
    }, [props.value]);

    const visibleItems = items.filter((item) => {
      if (typeof item.value !== 'string') {
        return false;
      }

      const formattedSearch = search.toLowerCase();

      return item.value.toLowerCase().includes(formattedSearch);
    });

    const clearSearch = () => {
      setSearch('');

      if (onClear && props.name) {
        onClear(props.name);
      }
    };

    return (
      <DropDown
        options={{ placement: 'bottom-start' }}
        items={visibleItems}
        config={{
          sameWidth: true,
          itemsElementClassName: (isOpen) =>
            clsx(
              'rounded-3xl w-full bg-[#F3F6FA] border border-outlineColor-input-border py-6',
              !isOpen && 'p-0 border-0',
            ),
          setSearch,
        }}
        renderElement={(isOpen) => (
          <TextField
            autoComplete="off"
            endIcon={
              <>
                {search && !props.disabled ? (
                  <IconButton
                    size="md"
                    className="absolute right-[40px] top-[4px]"
                    onClick={clearSearch}
                  >
                    <CloseCircleIcon className="fill-[#2E3A59] cursor-pointer" />
                  </IconButton>
                ) : undefined}

                {!props.disabled ? (
                  <ChevronUpIcon
                    className={`fill-[#2E3A59] absolute right-[12px] top-[12px] ${
                      isOpen ? 'rotate-0' : 'rotate-180'
                    }`}
                  />
                ) : undefined}
              </>
            }
            ref={ref}
            {...props}
            isRequired={isRequired}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
      />
    );
  },
);

SelectInput.displayName = 'SelectInput';

export { SelectInput };
export type { SelectInputProps };
