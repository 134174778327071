import { api } from 'src/store/api/api';
import { SurveyCategory } from 'src/shared/types';

export type CreateUpdateCategory = {
  name: string;
  subcategories?: string[];
  id?: string;
};

export const surveyCategoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSurveyCategories: builder.query<SurveyCategory[], ''>({
      query: () => ({
        url: '/survey-categories',
        method: 'GET',
      }),
      providesTags: ['SurveyCategory'],
      transformResponse: (response: { data: SurveyCategory[] }) => response.data,
    }),
    getSurveyCategory: builder.query<SurveyCategory, string>({
      query: (id) => ({
        url: `/survey-categories/${id}`,
        method: 'GET',
      }),
      providesTags: ['SurveyCategory'],
      transformResponse: (response: { data: SurveyCategory }) => response.data,
    }),
    createSurveyCategory: builder.mutation<SurveyCategory, CreateUpdateCategory>({
      query: (body) => ({
        url: '/survey-categories',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SurveyCategory'],
    }),
    copySurveyCategory: builder.mutation<SurveyCategory, { surveyId: string }>({
      query: (body) => ({
        url: '/survey-categories/copy',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SurveyCategory'],
    }),
    editSurveyCategory: builder.mutation<SurveyCategory, CreateUpdateCategory>({
      query: (body) => ({
        url: `/survey-categories`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['SurveyCategory'],
    }),
    deleteSurveyCategory: builder.mutation<SurveyCategory, string>({
      query: (id) => ({
        url: `/survey-categories/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { queryFulfilled, dispatch }) {
        dispatch(
          surveyCategoryApi.util.updateQueryData('getSurveyCategories', '', (categories) => {
            categories.splice(
              categories.findIndex((category) => category.id === id),
              1,
            );
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          dispatch(api.util.invalidateTags(['SurveyCategory']));
        }
      },
    }),
  }),
});

export const {
  useGetSurveyCategoriesQuery,
  useGetSurveyCategoryQuery,
  useCreateSurveyCategoryMutation,
  useEditSurveyCategoryMutation,
  useDeleteSurveyCategoryMutation,
} = surveyCategoryApi;
