import { FC } from 'react';
import { createPortal } from 'react-dom';

import { clsx } from 'src/shared/utils';
import { Typography } from 'src/shared/ui/typography';

type SpinnerProps = {
  size?: 'sm' | 'md' | 'lg';
  withBackdrop?: boolean;
  fallbackText?: string;
  portal?: boolean;
};

const Spinner: FC<SpinnerProps> = ({
  size = 'lg',
  withBackdrop = false,
  fallbackText,
  portal = false,
}) => {
  const className = clsx(
    'inline-block animate-spin rounded-full border-8 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]',
    size === 'sm' && 'h-8 w-8',
    size === 'md' && 'h-12 w-12',
    size === 'lg' && 'h-16 w-16',
  );

  const content = (
    <div
      className={className}
      role="status"
    />
  );

  const fallbackTextContainer = fallbackText && (
    <Typography variant="p1">{fallbackText}</Typography>
  );

  const spinner = (
    <div
      className={clsx(
        'flex flex-col justify-center items-center gap-y-1',
        (withBackdrop || portal) && 'absolute z-[100] w-full h-full top-0 left-0',
        withBackdrop && 'bg-slate-400 bg-opacity-30',
      )}
    >
      {content}

      {fallbackTextContainer}
    </div>
  );

  return portal ? createPortal(spinner, document.body) : spinner;
};

export { Spinner };
