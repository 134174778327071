import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';

import { ReactComponent as CloseIcon } from 'src/assets/icons/filled/edit/close.svg';
import { RootState, useAppDispatch } from 'src/store';
import { filtersActions } from 'src/store/slices/filters';
import { configActions, selectConfig } from 'src/store/slices/config';
import { BoardFilters, SidebarFilters } from 'src/store/api/api';
import { camelToTitleCase, camelize } from 'src/shared/utils';
import { useOutsideClick } from 'src/shared/hooks/useOutsideClick';

import { Icon } from '../icon';
import { Button } from '../button';

const emptyBoardFilters: Omit<BoardFilters, 'startDate' | 'endDate'> = {
  owner: [],
  ownerSite: [],
  jobCategory: [],
  serviceLine: [],
  providerArea: [],
  providerRegion: [],
  providerBranch: [],
  division: [],
};

const emptySidebarFilters: SidebarFilters = {
  providerAreaSidebar: [],
  providerRegionSidebar: [],
  providerBranchSidebar: [],
  divisionSidebar: [],
  equipmentTypeSidebar: [],
};

type FiltersProps = {
  closeMenu: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'board' | 'sidebar';
  title: string;
  className: string;
  children: React.ReactNode;
  isOpen?: boolean;
};

const Filters: React.FC<FiltersProps> = ({
  title,
  closeMenu,
  children,
  type,
  className,
  isOpen,
}) => {
  const dispatch = useAppDispatch();

  const config = useSelector(selectConfig);
  const allSelectedFilters = useSelector((state: RootState) => state.filters[type].selectedFilters);

  const filtersRef = useRef<HTMLDivElement>(null);

  const selectedValues = allSelectedFilters.map((el) => el.value);

  const boardFilters: BoardFilters = allSelectedFilters.reduce<Record<string, string[]>>(
    (acc, curr) => ({
      ...acc,
      [camelize(curr.id)]: [...(acc[camelize(curr.id)] || []), curr.value],
    }),
    emptyBoardFilters,
  );

  const sidebarFilters: SidebarFilters = allSelectedFilters.reduce<Record<string, string[]>>(
    (acc, curr) => ({
      ...acc,
      [camelize(curr.id)]: [...(acc[camelize(curr.id)] || []), curr.value],
    }),
    emptySidebarFilters,
  );

  const apply = () => {
    closeMenu(false);

    dispatch(
      configActions.setSelectedFilters({
        type,
        filters: type === 'board' ? boardFilters : sidebarFilters,
      }),
    );
  };

  const clearAll = () => {
    dispatch(filtersActions.removeFiltersByType(type));

    closeMenu(false);

    dispatch(
      configActions.setSelectedFilters({
        type,
        filters: type === 'board' ? emptyBoardFilters : emptySidebarFilters,
      }),
    );
  };

  const hideMenu = () => {
    closeMenu(false);
  };

  useOutsideClick(filtersRef, hideMenu, isOpen);

  useEffect(() => {
    const filters = {
      board: config.boardFilters,
      sidebar: config.sidebarFilters,
    };

    const optionValues = Object.entries(filters[type]).map(([key, optionValues]) => {
      const id = camelToTitleCase(key);

      return {
        id,
        options: optionValues as string[],
      };
    });

    dispatch(
      filtersActions.setOptionValues({
        type,
        optionValues,
      }),
    );
  }, [config.boardFilters, config.sidebarFilters, dispatch, type]);

  const content = (
    <div
      ref={filtersRef}
      className={clsx(
        className,
        'z-[100] absolute flex flex-col justify-between border border-outlineColor-input-border h-[532px] gap-[20px] bg-white p-4 rounded-2xl shadow-[0px_16px_46px_-11px_rgba(0,0,0,0.16)]',
      )}
    >
      <div className="flex flex-col gap-5 h-full">
        <div className="flex justify-between items-center gap-[22px] h-[40px]">
          <div className="text-xl font-bold text-textColor-primary">{title}</div>

          <div className="flex justify-between items-center gap-[22px]">
            {!!selectedValues.length && (
              <Button
                variant="outlined"
                color="primary"
                size="md"
                onClick={clearAll}
              >
                Clear all filters
              </Button>
            )}

            <Icon
              onClick={() => closeMenu(false)}
              icon={<CloseIcon />}
              className="cursor-pointer fill-bgColor-[#231F20] transition-transform duration-200 hover:scale-[1.2]"
            />
          </div>
        </div>

        <div className="min-h-[372px] max-h-[372px] overflow-y-scroll">{children}</div>
      </div>

      <Button
        size="lg"
        color="primary"
        onClick={apply}
      >
        Apply Filters
      </Button>
    </div>
  );

  return isOpen ? (
    <div className="absolute top-0 right-0 left-0 bottom-0 cursor-default">{content}</div>
  ) : (
    content
  );
};

export { Filters };
