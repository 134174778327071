import { Dispatch, FC, FormEvent, SetStateAction, useEffect, useState } from 'react';

import { SurveyCategory } from 'src/shared/types';
import { Button } from 'src/shared/ui/button';
import { Checkbox } from 'src/shared/ui/checkbox';
import { Modal } from 'src/shared/ui/modal';
import { Spinner } from 'src/shared/ui/spinner';
import { TextField } from 'src/shared/ui/textField';
import { Typography } from 'src/shared/ui/typography';
import { not, showToastErrorMessage } from 'src/shared/utils';
import { ReactComponent as PlusIcon } from 'src/assets/icons/filled/edit/plus.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/outlined/edit/trash.svg';
import {
  useCreateSurveyCategoryMutation,
  useEditSurveyCategoryMutation,
  useGetSurveyCategoriesQuery,
} from 'src/store/api/survey-category';
import { Icon } from 'src/shared/ui/icon';
import { Table, TableHeaderType } from 'src/shared/ui/table';
import { IconButton } from 'src/shared/ui/iconButton';

type CreateOrUpdateSurveyCategoryModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  category?: SurveyCategory;
};

const CreateOrUpdateSurveyCategoryModal: FC<CreateOrUpdateSurveyCategoryModalProps> = ({
  isOpen,
  setIsOpen,
  category,
}) => {
  const { data: categories = [] } = useGetSurveyCategoriesQuery('');

  const [createCategory, { isLoading: isCreating }] = useCreateSurveyCategoryMutation();
  const [updateCategory, { isLoading: isUpdating }] = useEditSurveyCategoryMutation();

  const [name, setName] = useState('');
  const [addSubcategory, setAddSubcategory] = useState(false);
  const [subcategoryName, setSubcategoryName] = useState('');
  const [subcategoryNames, setSubcategoryNames] = useState<string[]>([]);

  const isUpdateState = !!category;
  const isSubmitting = isCreating || isUpdating;
  const isCategoryAlreadyExists =
    !!categories.find((category) => category.name === name) && name !== category?.name;
  const isSubcategoryAlreadyExists = !!subcategoryNames.find((name) => name === subcategoryName);

  const type = isUpdateState ? 'Update' : 'Create';

  const closeModal = () => {
    setIsOpen(false);
    setName('');
    setSubcategoryName('');
    setSubcategoryNames([]);
    setAddSubcategory(false);
  };

  const addSubcategoryName = () => {
    setSubcategoryNames([...subcategoryNames, subcategoryName]);
    setSubcategoryName('');
  };

  const deleteSubcategoryName = (subcategoryName: string) => {
    setSubcategoryNames(subcategoryNames.filter((name) => name !== subcategoryName));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (isUpdateState && category) {
        await updateCategory({
          name,
          id: category.id,
        }).unwrap();
      } else {
        await createCategory({
          name,
          subcategories: addSubcategory ? subcategoryNames : [],
        }).unwrap();
      }
    } catch (error) {
      showToastErrorMessage(
        `There was an error trying to ${isUpdateState ? 'update' : 'create'} category`,
      );
    } finally {
      closeModal();
    }
  };

  useEffect(() => {
    setName(category?.name || '');
  }, [category, isOpen]);

  const headers: TableHeaderType<{ subcategoryName: string }>[] = [
    {
      title: 'Name',
      field: 'subcategoryName',
      className: 'text-md w-[450px]',
    },
    {
      title: 'Action',
      field: 'action',
      className: 'text-md',
      render: ({ subcategoryName }) => (
        <IconButton
          size="sm"
          onClick={() => deleteSubcategoryName(subcategoryName)}
          disabled={isSubmitting}
        >
          <TrashIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={closeModal}
    >
      <form
        className="flex flex-col gap-y-6 w-[700px]"
        onSubmit={handleSubmit}
      >
        <Typography variant="h2">{`${type} Category`}</Typography>

        <div className="flex flex-col gap-y-6">
          <div className="h-[100px]">
            <TextField
              label="Category Name"
              placeholder="Enter Category name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={isCategoryAlreadyExists ? 'Category already exists' : ''}
              isRequired
            />
          </div>

          {not(isUpdateState) && (
            <>
              <Checkbox
                endLabel="Add subcategory"
                checked={addSubcategory}
                onChange={() => setAddSubcategory(not(addSubcategory))}
                disabled={isSubmitting}
              />

              {addSubcategory && (
                <>
                  <div className="flex flex-col gap-y-2 h-[110px]">
                    <div className="flex items-end gap-x-4">
                      <TextField
                        label="Subcategory Name"
                        placeholder="Enter Subcategory name"
                        value={subcategoryName}
                        onChange={(e) => setSubcategoryName(e.target.value)}
                        isRequired
                        disabled={isUpdateState}
                      />

                      <Button
                        color="primary"
                        size="lg"
                        className="!w-12"
                        onClick={addSubcategoryName}
                        disabled={isSubmitting || isSubcategoryAlreadyExists}
                      >
                        <Icon
                          icon={<PlusIcon />}
                          className="fill-white"
                        />
                      </Button>
                    </div>

                    {isSubcategoryAlreadyExists ? (
                      <p className="text-semanticColor-danger text-[14px] leading-[150%]">
                        Subcategory already exists
                      </p>
                    ) : null}
                  </div>

                  {!!subcategoryNames.length && (
                    <Table
                      headers={headers}
                      data={subcategoryNames.map((subcategoryName) => ({
                        subcategoryName,
                      }))}
                      variant="secondary"
                      scroll
                      containerClassName="max-h-[165px]"
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>

        <div className="flex w-full justify-end gap-x-2">
          <Button
            variant="outlined"
            onClick={closeModal}
            disabled={isSubmitting}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            disabled={isCategoryAlreadyExists || not(name) || isSubmitting}
          >
            {isSubmitting ? <Spinner size="sm" /> : type}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { CreateOrUpdateSurveyCategoryModal };
