import 'survey-core/defaultV2.css';
import 'survey-creator-core/survey-creator-core.css';
import { useEffect, useState } from 'react';

import { SurveyCategory } from 'src/shared/types';
import { useLocalStorage } from 'src/shared/hooks/useLocalStorage';

import { SurveyCategoriesSidebar, SurveyCategoryContent } from './Features';

const Forms = () => {
  const [expandedCategory, setExpandedCategory] = useState<SurveyCategory | null>(null);
  const [expandedCategoryLocalStorage, setExpandedCategoryLocalStorage] = useLocalStorage(
    'expandedCategory',
    '',
  );

  useEffect(() => {
    setExpandedCategory(JSON.parse(expandedCategoryLocalStorage || '{}'));
  }, [expandedCategoryLocalStorage]);

  return (
    <div className="flex w-full h-full justify-between">
      <SurveyCategoriesSidebar
        expandedCategory={expandedCategory}
        setExpandedCategory={setExpandedCategory}
      />

      <SurveyCategoryContent expandedCategory={expandedCategory} />
    </div>
  );
};

export { Forms };
