import { Model } from 'survey-core';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabulator, TableExtensions } from 'survey-analytics/survey.analytics.tabulator';
import { DocumentHelper } from 'survey-analytics';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'src/shared/ui/typography';
import { useGetSurveysQuery } from 'src/store/api/survey';
import { getFormattedTimePickerTime, not, showToastErrorMessage } from 'src/shared/utils';
import 'tabulator-tables/dist/css/tabulator.min.css';
import 'survey-analytics/survey.analytics.tabulator.css';
import { BackButton } from 'src/shared/ui/backButton';
import { useDeleteSurveyAnswerMutation } from 'src/store/api/surveyAnswer';
import { Spinner } from 'src/shared/ui/spinner';
import { selectCurrentUser } from 'src/store/slices';
import { Role, SurveyAnswerEntity } from 'src/shared/types';
import { ADMIN_ROLES } from 'src/shared/constants';
import { getFormattedDate } from 'src/shared/ui/details/helpers';

import { DeleteSurveyAnswerModal } from './Features/DeleteSurveyAnswerModal';

const CONTAINER_ID = 'survey-answers-container';

const SurveyAnswers = () => {
  const navigate = useNavigate();

  const { formId } = useParams();
  const { data: forms = [], isFetching: isLoadingForm } = useGetSurveysQuery('', {
    refetchOnMountOrArgChange: true,
  });

  const user = useSelector(selectCurrentUser);

  const userRole = user?.ProviderRoleMatrix?.userRole as Role | undefined;
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  const [deleteAnswer, { isLoading: isDeletingAnswer }] = useDeleteSurveyAnswerMutation();

  const [vizPanel, setVizPanel] = useState<Tabulator>();
  const [isDeleteSurveyAnswerModalOpen, setIsDeleteSurveyAnswerModalOpen] = useState(false);
  const [selectedSurveyAnswer, setSelectedSurveyAnswer] = useState<SurveyAnswerEntity>();

  const form = forms.find((form) => form.id === formId);

  useEffect(() => {
    if (not(form)) {
      return;
    }

    const survey = new Model(form.content);

    survey.pages[0].addNewQuestion('text', 'Created At');
    survey.pages[0].addNewQuestion('text', 'Updated At');

    const vizPanel = new Tabulator(
      survey as any,
      form.answers.map((answer) => ({
        ...answer.answer,
        'Created At': `${getFormattedDate(answer.createdAt)} ${getFormattedTimePickerTime(
          answer.createdAt,
        )}`,
        'Updated At': `${getFormattedDate(answer.updatedAt)} ${getFormattedTimePickerTime(
          answer.updatedAt,
        )}`,
      })),
    );

    setVizPanel(vizPanel);
  }, [form, navigate]);

  useEffect(() => {
    if (vizPanel) {
      vizPanel.render(CONTAINER_ID);

      const viewAnswerName = 'view-answer';
      const editAnswerName = 'edit-answer';
      const deleteAnswerName = 'delete-answer';

      TableExtensions.unregisterExtension('details', viewAnswerName);
      TableExtensions.unregisterExtension('details', editAnswerName);
      TableExtensions.unregisterExtension('details', deleteAnswerName);

      TableExtensions.registerExtension({
        location: 'details',
        name: viewAnswerName,
        visibleIndex: 1,
        render: (table, opt) => {
          const btn = DocumentHelper.createElement(
            'button',
            'sa-table__btn sa-table__btn--small mr-1 transition-all hover:opacity-[0.8] duration-200',
            {
              innerHTML: 'View',
              onclick: () => {
                if (not(form)) {
                  return;
                }

                const answer = form.answers[opt.row.getDataPosition()];
                navigate(`/forms/${formId}/answers/${answer.id}`);
              },
            },
          );

          return btn;
        },
      });

      if (not(isReadonly)) {
        TableExtensions.registerExtension({
          location: 'details',
          name: editAnswerName,
          visibleIndex: 1,
          render: (table, opt) => {
            const btn = DocumentHelper.createElement(
              'button',
              'sa-table__btn sa-table__btn--small mr-1 transition-all hover:opacity-[0.8] duration-200',
              {
                innerHTML: 'Edit',
                onclick: () => {
                  if (not(form)) {
                    return;
                  }

                  const answer = form.answers[opt.row.getDataPosition()];
                  navigate(`/forms/${formId}/answers/${answer.id}/edit`);
                },
              },
            );

            return btn;
          },
        });

        if (!userRole || !ADMIN_ROLES.includes(userRole)) {
          return;
        }

        TableExtensions.registerExtension({
          location: 'details',
          name: deleteAnswerName,
          visibleIndex: 1,
          render: (table, opt) => {
            const btn = DocumentHelper.createElement(
              'button',
              'sa-table__btn sa-table__btn--small border border-semanticColor-danger text-white !bg-semanticColor-danger transition-all hover:opacity-[0.8] duration-200',
              {
                innerHTML: 'Delete',
                onclick: async () => {
                  if (not(form)) {
                    return;
                  }

                  const answer = form.answers[opt.row.getDataPosition()];

                  setIsDeleteSurveyAnswerModalOpen(true);
                  setSelectedSurveyAnswer(answer);
                },
              },
            );
            return btn;
          },
        });
      }
    }
  }, [deleteAnswer, form, formId, isReadonly, navigate, userRole, vizPanel]);

  if (not(form) && not(isLoadingForm)) {
    showToastErrorMessage('There was an error trying to load the form');

    navigate('/forms');

    return null;
  }

  return (
    <>
      {isLoadingForm && (
        <Spinner
          withBackdrop
          fallbackText="Loading form answers..."
        />
      )}

      {isDeletingAnswer && (
        <Spinner
          withBackdrop
          fallbackText="Deleting form..."
        />
      )}

      <div className="flex flex-col w-full gap-y-4 pt-8 pl-4">
        <div className="flex items-center gap-x-4">
          <BackButton href="/forms" />

          <Typography variant="h1">Survey Answers</Typography>
        </div>

        <div
          id={CONTAINER_ID}
          className="w-full h-[70%]"
        />
      </div>

      <DeleteSurveyAnswerModal
        isOpen={isDeleteSurveyAnswerModalOpen}
        setIsOpen={setIsDeleteSurveyAnswerModalOpen}
        answer={selectedSurveyAnswer}
      />
    </>
  );
};

export { SurveyAnswers };
